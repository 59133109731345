import ArrowButtonSVG from 'assets/svg/arrow-button.svg'
import { useMotionValueEvent, useScroll } from 'framer-motion'
import Link from 'next/link'
import { useRef, useState } from 'react'
import { WaveDividerSVG } from './svg/WaveDividerSVG'

export function HeroSection({
  children,
  background = 'dark',
}: {
  children: React.ReactNode
  background?: 'dark' | 'light'
}) {
  const bgMap = {
    dark: 'bg-twilight-900',
    light: 'bg-lagoon-300',
  }

  return (
    <div
      className={`flex flex-col items-center justify-center min-h-[640px] w-full h-[80vh] lg:h-[90vh] ${bgMap[background]}`}
    >
      <div className='relative flex w-full flex-col items-center justify-between gap-16 pb-24 pt-40 lg:pb-48 lg:pt-60 max-h-[800px] h-full'>
        {children}
      </div>
    </div>
  )
}

export function HeroTitle({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <h1 className={`relative text-center -mx-8 text-5xl font-bold lg:text-6xl ${className}`}>
      {children}
    </h1>
  )
}

export function HeroDescription({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <p className={`px-4 text-center text-xl font-semibold max-w-2xl ${className}`}>{children}</p>
  )
}

export function HeroButton({
  children,
  targetId,
  color = 'accent',
}: {
  children: React.ReactNode
  targetId?: string
  color?: 'accent' | 'light'
}) {
  const textColorMap = {
    accent: 'text-saffron-500',
    light: 'text-light',
  }

  return (
    <div className='h-6 animate-bounce'>
      <div className='overflow-hidden rounded-lg'>
        <button
          className={`tap-highlight mouse:hover:brightness-200 flex items-center gap-4 overflow-hidden px-4 py-2 text-3xl font-semibold transition ${textColorMap[color]}`}
          onClick={() => {
            const targetElement = document.getElementById(targetId)
            const elementRect = targetElement.getBoundingClientRect()
            const viewportHeight = window.innerHeight
            const scrollToPosition =
              elementRect.top + window.scrollY - viewportHeight / 2 + elementRect.height / 2

            window.scrollTo({
              top: scrollToPosition - 100,
              behavior: 'smooth',
            })
          }}
        >
          <span>{children}</span>
          <span className='inline-block'>
            <ArrowButtonSVG className='h-12 w-12 stroke-current stroke-[8]' />
          </span>
        </button>
      </div>
    </div>
  )
}

export function WavyDivider({
  flipped = false,
  pullFront = false,
  variant = 'classic',
}: {
  flipped?: boolean
  pullFront?: boolean
  variant?: 'classic' | 'dark' | 'light'
}) {
  return (
    <div
      className={`relative flex h-0 w-full max-w-7xl justify-center self-center ${
        pullFront ? 'z-10' : ''
      }`}
    >
      <div
        className={`absolute w-[210%] -translate-y-full ${
          flipped ? '-top-px origin-bottom rotate-180 transform' : 'top-px'
        }`}
      >
        <WaveDividerSVG variant={variant} />
      </div>
    </div>
  )
}

export function ScrollCounter({ start = 0, end = 100 }: { start?: number; end?: number }) {
  const ref = useRef<HTMLSpanElement>(null)

  // @ts-ignore
  const { scrollYProgress } = useScroll({ target: ref, offset: ['-100vh', '-60vh'] })

  const [value, setValue] = useState(0)

  useMotionValueEvent(scrollYProgress, 'change', (latest: number) => {
    const newValue = Math.floor(start + latest * (end - start))
    setValue(prev => Math.max(prev, newValue))
  })

  return <span ref={ref} className='inline-block w-28 text-right'>{`${value}`}</span>
}

export function CTALink({ children, href }: { children: React.ReactNode; href: string }) {
  return (
    <Link
      href={href}
      className='btn px-6 text-2xl xl:rounded-2xl xl:border-[6px] xl:px-10 xl:py-3 xl:text-4xl min-w-[200px]'
    >
      {children}
    </Link>
  )
}
