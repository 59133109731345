import { useEffect, useRef, useState } from 'react'

export function useOnScreen(threshold: number = 0.5) {
  const target = useRef()
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      { threshold },
    )
    const currentTarget = target.current
    if (currentTarget) {
      observer.observe(currentTarget)
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget)
      }
    }
  }, [threshold]) // Empty array ensures that effect is only run on mount and unmount

  return [target, isIntersecting] as const
}

export function useDevelopment() {
  return process.env.NODE_ENV === 'development'
}
