const variants = {
  classic: '',
  dark: '[&_.front]:fill-obsidian [&_.middle]:fill-pacific/25',
  light: '[&_.front]:fill-pacific-500 [&_.middle]:fill-pacific-200 [&_.back]:fill-pacific-600',
}

export const WaveDividerSVG = ({
  variant = 'classic',
}: {
  variant: 'classic' | 'dark' | 'light'
}) => (
  <svg
    viewBox='0 0 1000 30'
    version='1.1'
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    className={variants[variant]}
  >
    <g>
      <path
        className='back fill-grape-700'
        d='M1020.5,20.377l0,7.801l-1250.29,-0l0,-28.283c59.538,0 119.075,20.482 178.613,20.482c59.538,0 119.075,-20.482 178.613,-20.482c59.537,0 119.075,20.482 178.613,20.482c59.537,0 119.075,-20.482 178.613,-20.482c59.537,0 119.075,20.482 178.613,20.482c59.537,0 119.075,-20.482 178.613,-20.482c59.537,0 119.075,20.482 178.612,20.482Z'
      />
      <path
        className='middle fill-steel'
        d='M1220.64,26.636l-0,1.542l-1250.29,-0l0,-22.024c59.538,0 119.076,20.482 178.613,20.482c59.538,0 119.076,-20.482 178.613,-20.482c59.538,0 119.075,20.482 178.613,20.482c59.538,0 119.075,-20.482 178.613,-20.482c59.538,0 119.075,20.482 178.613,20.482c59.538,0 119.075,-20.482 178.613,-20.482c59.538,0 119.075,20.482 178.613,20.482Z'
      />
      <path
        className='front fill-twilight-900'
        d='M1094.93,23.507l-0,6.388l-1250.29,0l0,-26.87c59.538,-0 119.075,20.482 178.613,20.482c59.538,-0 119.075,-20.482 178.613,-20.482c59.538,-0 119.075,20.482 178.613,20.482c59.537,-0 119.075,-20.482 178.613,-20.482c59.537,-0 119.075,20.482 178.613,20.482c59.537,-0 119.075,-20.482 178.613,-20.482c59.537,-0 119.075,20.482 178.613,20.482Z'
      />
    </g>
  </svg>
)
