export const TexturedWaveDividerSVG = ({ ...props }) => {
  return (
    <svg viewBox='0 0 1000 2210' xmlns='http://www.w3.org/2000/svg' {...props}>
      <defs>
        <clipPath id='wdt__clip'>
          <path
            id='wdt__texture-mask-path'
            d='M0,2217.31l0,-2053.92c33.916,15.389 67.832,28.521 101.748,37.368c168.584,-37.772 337.167,-189.354 505.751,-189.354c24.045,-0 48.09,3.084 72.135,8.481c38.047,-12.301 76.094,-19.882 114.141,-19.882c68.742,-0 137.483,24.748 206.225,57.364l-0,2159.95l-1000,-0Z'
          />
        </clipPath>
      </defs>
      <g clipPath='url(#wdt__clip)'>
        <path
          d='M0,163.387c63.024,28.596 126.048,49.399 189.073,49.399c201.567,-0 403.135,-212.786 604.702,-212.786c68.742,-0 137.483,24.748 206.225,57.364l-0,298.579l-1000,0l0,-192.556Z'
          fill='#948da3'
        />
        <path
          d='M-0,207.68c9.922,0.996 19.845,1.521 29.767,1.521c192.577,0 385.155,-197.8 577.732,-197.8c130.834,-0 261.667,91.297 392.501,149.839l-0,181.037l-1000,-0l-0,-134.597Z'
          fill='#4c4166'
        />
        <path
          d='M0,2220.17l0,-2019.06c56.297,21.46 112.594,36.362 168.891,36.362c201.085,-0 402.17,-190.122 603.255,-190.122c75.952,-0 151.903,27.123 227.854,60.88l0,2111.94l-1000,0Z'
          fill='#1f1140'
        />
        <g
          id='wdt__texture-paths'
          fill='none'
          stroke='#e9e9f0'
          opacity='0.53'
          strokeWidth='4px'
          style={{
            mixBlendMode: 'overlay',
          }}
          shapeRendering='optimizeSpeed'
        >
          <path d='M0,2195.63c335.712,0.148 671.424,-0 1007.14,-0' />
          <path d='M0,2165.12c91.222,0.254 182.507,-1.745 273.673,1.689c77.061,2.904 153.678,-4.869 230.591,-3.01c76.581,1.851 153.261,-4.226 229.821,-0.502c90.805,4.423 182.133,1.823 273.044,1.823' />
          <path d='M0,2134.62c92.056,0.021 184.111,-3.116 275.856,5.335c38.743,3.568 78.433,5.292 117.175,0.862c37.888,-4.339 74.603,-11.567 112.922,-10.62c75.754,1.872 151.749,-10.507 227.306,-1.336c90.409,10.981 182.889,5.752 273.877,5.752' />
          <path d='M0,2104.12c49.066,-0.297 97.977,-0.883 147.071,-0.226c44.947,0.601 87.343,3.71 131.859,10.684c39.47,6.183 79.125,9.504 118.489,1.039c37.323,-8.027 72.285,-20.725 110.958,-20.343c74.751,0.734 149.565,-18.103 223.716,-2.431c22.286,4.713 44.664,10.698 67.565,11.348c19.82,0.565 39.781,-0.078 59.608,-0.078l147.87,0' />
          <path d='M0,2073.61c48.925,-0.749 97.426,-1.392 146.421,-0.402c20.612,0.417 41.471,-0.594 61.969,1.907c25.063,3.06 49.589,9.532 74.186,15.051c40.855,9.165 79.669,14.74 120.058,1.123c36.411,-12.273 69.282,-31.203 108.64,-31.811c74.058,-1.137 146.555,-26.278 219.441,-3.695c22.505,6.974 45.166,15.771 68.872,17.552c19.7,1.477 39.944,0.282 59.679,0.282l147.87,0' />
          <path d='M0,2043.11c22.025,-0.587 43.59,-3.3 65.7,-2.926c26.674,0.452 53.334,1.597 80.008,2.318c21.12,0.572 42.41,-0.438 63.311,3.144c26.349,4.522 51.85,13.122 77.471,20.541c42.516,12.309 80.411,20.732 121.747,1.173c35.097,-16.605 65.855,-42.233 106.153,-44.085c34.588,-1.59 67.261,-8.338 101.234,-14.428c15.814,-2.834 31.846,-5.116 47.936,-5.54c23.416,-0.615 44.19,6.133 65.664,14.93c22.696,9.299 45.639,21.057 70.264,24.166c19.481,2.459 40.191,0.699 59.771,0.699l147.87,0' />
          <path d='M0,2012.6c21.17,-0.813 41.47,-4.205 62.802,-3.738c27.417,0.6 54.804,2.021 82.206,2.932c21.672,0.721 43.322,-0.268 64.626,4.367c27.635,6.013 54.097,16.697 80.736,25.981c23.211,8.091 48.642,17.955 73.578,18.273c17.983,0.226 34.892,-7.758 49.843,-17.043c33.345,-20.703 62.442,-53.094 103.686,-56.231c33.797,-2.572 64.569,-10.684 97.412,-18.471c15.157,-3.596 30.758,-6.613 46.374,-7.165c24.208,-0.862 45.244,8.55 66.491,19.262c22.859,11.525 46.056,26.229 71.649,30.723c19.199,3.371 40.467,1.11 59.856,1.11l147.87,-0' />
          <path d='M0,1982.1c20.428,-1.004 39.449,-5.017 60.153,-4.48c28.094,0.735 56.153,2.409 84.233,3.505c22.18,0.862 44.106,-0.142 65.763,5.405c28.837,7.384 56.175,20.004 83.739,31.006c23.396,9.334 50.12,21.636 75.804,21.989c18.252,0.254 35.118,-9.991 49.13,-20.654c31.613,-24.06 59.164,-63.085 101.447,-67.332c33.231,-3.335 62.081,-12.733 93.928,-22.144c14.542,-4.297 29.748,-7.957 44.968,-8.635c24.943,-1.117 46.296,10.853 67.247,23.169c22.964,13.51 46.402,31.006 72.921,36.729c18.915,4.084 40.721,1.435 59.926,1.435l147.87,-0' />
          <path d='M0,1951.59c19.848,-1.124 37.725,-5.674 57.991,-5.081c28.666,0.841 57.291,2.735 85.936,3.979c22.526,0.982 44.685,-0.113 66.569,6.105c29.868,8.486 57.934,22.773 86.268,35.224c23.544,10.344 51.229,24.561 77.578,24.95c18.633,0.268 35.252,-11.638 48.586,-23.403c30.122,-26.589 56.273,-71.571 99.609,-76.447c32.977,-3.71 60.011,-14.28 91.073,-25.134c14.04,-4.903 28.914,-9.016 43.859,-9.793c25.621,-1.329 47.179,12.69 67.869,26.285c22.999,15.122 46.649,35.118 73.973,41.711c18.697,4.508 40.905,1.611 59.962,1.611l147.87,-0' />
          <path d='M0,1921.09c19.46,-1.145 36.517,-6.105 56.528,-5.49c29.076,0.897 58.111,2.967 87.166,4.324c22.597,1.053 44.982,-0.254 66.872,6.296c30.653,9.172 59.227,24.766 88.092,38.269c23.721,11.094 51.765,26.371 78.673,26.773c19.078,0.283 35.259,-12.21 48.275,-24.872c29.055,-28.271 53.998,-77.931 98.365,-82.651c33.069,-3.526 58.563,-15.156 89.145,-27.126c13.75,-5.384 28.348,-9.673 43.18,-10.5c26.243,-1.463 47.716,13.665 68.307,28.236c22.901,16.202 46.77,38.325 74.708,45.194c18.591,4.564 40.99,1.554 59.948,1.554l147.87,0' />
          <path d='M0,1890.58c8.592,-0.41 14.874,-2.579 23.007,-4.501c10.747,-2.537 22.152,-1.583 33.09,-1.258c29.232,0.862 58.4,3.215 87.611,4.65c22.385,1.102 45.01,-0.657 66.71,5.815c31.055,9.271 59.814,25.763 88.834,39.838c23.911,11.595 51.588,26.844 78.913,27.19c19.523,0.247 35.125,-11.426 48.303,-24.816c28.68,-29.147 52.457,-81.117 97.821,-85.039c33.217,-2.869 58.273,-15.277 88.671,-27.55c13.694,-5.533 28.208,-9.822 43.039,-10.642c26.49,-1.476 47.844,13.779 68.434,28.717c22.724,16.492 46.565,39.272 74.638,45.992c18.852,4.515 41.732,1.067 60.958,1.166c49.13,0.261 98.26,0.629 147.39,0.438' />
          <path d='M0,1860.08c9.68,-0.466 15.164,-3.632 24.003,-6.402c10.557,-3.313 21.99,-2.296 32.914,-1.886c28.624,1.06 57.086,4.656 85.632,6.769c26.045,1.929 51.278,-0.141 76.172,9.115c26.787,9.956 51.631,24.745 76.842,38.015c23.919,12.592 50.96,28.49 78.659,29.953c22.915,1.208 39.859,-13.03 54.281,-29.267c26.9,-30.299 48.105,-77.649 92.451,-82.757c31.528,-3.632 58.838,-12.443 88.812,-22.3c15.962,-5.25 33.154,-10.281 50.091,-10.621c24.371,-0.487 45.696,13.306 65.466,26.145c19.1,12.4 38.1,26.815 60.005,33.952c23.84,7.765 52.557,1.582 77.528,2.967c51.617,2.862 103.043,6.317 154.801,6.056' />
          <path d='M0,1829.58c10.995,-1.059 16.019,-5.546 25.628,-9.765c10.599,-4.656 20.796,-4.07 32.214,-3.399c27.353,1.612 54.38,6.72 81.542,10.077c24.434,3.017 49.942,0.636 73.26,9.334c29.776,11.107 56.577,29.677 83.845,45.653c22.477,13.171 48.494,30.052 75.323,31.578c22.258,1.272 38.573,-13.454 51.956,-29.536c24.915,-29.96 46.191,-83.28 90.261,-86.94c32.878,-2.727 64.011,-8.733 96.338,-15.192c17.022,-3.398 34.602,-6.705 52.013,-6.981c20.724,-0.325 39.159,6.833 57.729,15.411c18.675,8.628 36.969,18.676 57.602,21.77c14.245,2.134 28.85,-0.777 43.187,-0.869c14.93,-0.092 29.833,1.025 44.643,2.883c25.339,3.173 50.501,9.469 76.079,10.423c32.334,1.208 64.675,4.253 97.023,4.776' />
          <path d='M0,1799.07c16.273,-2.643 25.805,-17.029 41.64,-19.707c19.191,-3.244 42.594,3.695 61.311,7.44c18.888,3.787 37.712,8.133 56.846,10.542c22.138,2.792 40.912,3.102 61.383,13.306c42.982,21.417 78.545,56.379 122.983,75.026c37.478,15.729 60.661,-3.723 81.633,-34.178c17.75,-25.777 34.744,-67.077 66.392,-78.687c13.207,-4.847 29.911,-2.529 43.866,-3.165c14.153,-0.643 28.285,-1.696 42.41,-2.834c27.812,-2.24 55.602,-4.748 83.52,-5.214c25.021,-0.417 48.748,3.462 73.486,6.055c20.569,2.155 40.269,-0.713 60.853,-1.477c29.917,-1.116 59.234,2.89 88.36,9.462c59.255,13.369 119.316,19.502 180.161,22.01' />
          <path d='M0,1768.57c17.806,-3.929 26.985,-21.566 44.127,-25.572c19.135,-4.473 44.707,5.087 63.029,10.034c17.785,4.797 35.52,10.168 53.708,13.262c21.474,3.654 39.315,3.873 58.924,14.797c43.067,23.996 77.308,62.583 122.22,83.414c38.184,17.707 60.831,-6.741 80.072,-38.136c15.919,-25.981 31.358,-67.663 62.11,-79.541c13.277,-5.123 29.995,-2.269 44.014,-1.915c15.008,0.374 30.023,0.763 45.031,1.152c30.61,0.791 61.227,1.596 91.837,2.338c23.091,0.566 40.643,-5.765 61.156,-15.057c48.31,-21.884 107.565,-12.599 156.935,1.13c24.357,6.777 48.749,16.867 74.101,19.206c44.453,4.105 88.99,10.168 133.548,12.824' />
          <path d='M0,1738.06c20.032,-5.088 30.327,-27.614 50.444,-30.568c18.202,-2.671 39.683,5.992 56.705,11.56c18.392,6.021 36.651,12.903 55.708,16.584c21.551,4.169 38.029,4.565 57.581,16.634c41.83,25.819 74.327,65.826 118.284,88.289c13.687,6.995 28.215,11.631 42.714,4.388c16.217,-8.098 26.526,-25.706 35.238,-40.856c15.446,-26.85 29.713,-71.818 61.17,-84.41c13.249,-5.306 30.165,-2.049 44.106,-1.053c16.98,1.216 33.917,2.876 50.854,4.579c31.91,3.222 63.947,7.257 96.027,8.303c28.009,0.911 34.362,-11.603 49.116,-31.734c13.128,-17.912 32.8,-19.127 53.743,-20.152c39.542,-1.936 78.058,4.819 115.593,17.057c24.865,8.112 49.836,20.28 76.101,23.007c49.122,5.102 98.358,12.422 147.636,15.807' />
          <path d='M0,1707.55c20.873,-5.144 30.56,-29.373 51.37,-32.751c18.011,-2.925 39.188,5.999 55.786,12.033c17.968,6.529 35.754,14.097 54.507,18.096c20.887,4.459 38.121,4.134 57.001,15.828c42.05,26.045 73.14,67.24 116.426,91.448c38.821,21.707 61.898,-2.621 80.341,-36.616c14.697,-27.084 28.03,-72.652 59.439,-85.194c13.001,-5.194 30.207,-2.064 43.837,-0.884c17.432,1.505 34.772,3.858 52.09,6.31c32.589,4.607 65.219,10.055 98.154,11.391c12.21,0.494 25.459,1.236 33.641,-9.391c6.911,-8.967 7.172,-21.452 11.701,-31.521c10.168,-22.597 35.224,-21.799 57.327,-23.014c38.983,-2.134 76.871,4.31 113.677,16.958c26.307,9.038 53.031,24.745 81.111,27.515c51.143,5.045 102.252,13.63 153.544,17.015' />
          <path d='M0,1677.05c19.764,-5.723 32.468,-27.253 52.493,-30.391c17.397,-2.727 39.118,5.914 55.68,10.755c18.04,5.271 35.959,11.15 54.401,14.866c20.788,4.198 38.46,4.558 57.023,16.16c42.276,26.42 73.034,68.59 116.624,93.081c38.29,21.516 61.594,-3.738 80.884,-36.291c15.849,-26.759 30.589,-70.031 61.637,-82.997c13.121,-5.484 30.186,-2.092 44.007,-0.961c16.859,1.385 33.662,3.356 50.444,5.412c31.726,3.887 63.445,8.246 95.391,9.871c12.747,0.65 25.034,1.428 33.902,-9.108c7.73,-9.178 9.638,-22.074 15.128,-32.482c11.483,-21.791 33.924,-21.325 56.747,-22.442c37.825,-1.851 74.299,5.095 109.763,17.941c26.328,9.532 52.812,25.063 81.182,27.451c50.578,4.247 100.796,12.945 151.445,16.443' />
          <path d='M0,1646.55c19.58,-5.031 36.072,-21.615 55.991,-23.925c17.679,-2.049 38.502,3.985 56.019,6.741c36.192,5.688 81.139,4.451 112.229,25.133c42.149,28.038 72.978,72.158 117.755,96.338c35.973,19.424 60.188,-5.356 80.439,-34.185c18.16,-25.848 35.683,-66.187 67.07,-78.652c13.341,-5.299 29.706,-2.247 43.746,-1.413c15.707,0.933 31.394,2.127 47.08,3.356c29.819,2.346 59.652,5.003 89.548,6.176c12.598,0.495 23.572,0.608 33.302,-8.486c9.864,-9.221 14.761,-22.541 22.307,-33.479c13.821,-20.032 31.868,-21.77 55.341,-22.858c38.064,-1.767 74.171,6.776 109.063,21.622c23.24,9.885 46.6,24.003 72.25,26.207c48.247,4.148 96.309,11.723 144.641,14.966' />
          <path d='M0,1616.04c19.347,-3.179 37.697,-11.849 57.086,-14.308c19.665,-2.495 41.174,0.558 61.086,0.113c36.764,-0.827 77.556,-6.875 109.43,14.789c43.343,29.465 74.101,76.214 120.165,101.821c35.542,19.756 62.604,-5.766 85.18,-32.249c20.407,-23.933 40.955,-58.966 72.186,-69.869c13.447,-4.692 29.147,-2.226 43.202,-1.985c13.185,0.226 26.37,0.339 39.562,0.431c27.112,0.183 54.217,0.31 81.323,0.961c15.319,0.367 26.85,-0.615 39.11,-10.896c11.369,-9.539 19.488,-22.414 29.493,-33.253c38.1,-41.279 108.64,-21.042 152.442,0.869c21.346,10.677 42.869,26.053 67.204,28.611c44.615,4.684 89.738,10.189 134.53,12.881' />
          <path d='M0,1585.54c42.028,-1.879 84.121,-1.893 125.238,-11.425c37.16,-8.614 72.843,-19.842 106.597,3.618c43.42,30.178 73.606,76.729 118.398,105.092c35.365,22.392 65.134,1.696 92.274,-23.261c24.124,-22.187 48.445,-53.9 81.238,-63.269c23.353,-6.67 52.154,-2.954 76.588,-5.547c22.788,-2.416 45.463,-4.897 68.413,-5.215c16.782,-0.233 29.6,-2.035 43.661,-11.75c14.641,-10.119 26.575,-23.601 40.057,-35.118c40.884,-34.927 97.313,-23.226 139.998,3.229c20.576,12.754 39.499,29.253 64.364,31.783c38.92,3.964 78.327,8.309 117.402,10.203' />
          <path d='M0,1555.03c23.353,1.738 46.473,7.963 69.854,8.776c20.463,0.706 40.064,-7.879 58.372,-16.04c17.086,-7.61 33.938,-16.294 52.091,-21.142c21.636,-5.78 37.817,-1.66 55.998,11.391c44.593,32.03 74.793,81.329 120.941,111.331c33.868,22.018 65.537,4.508 95.137,-16.562c28.087,-19.99 56.372,-48.275 91.052,-55.97c23.07,-5.116 46.522,-3.264 69.685,-9.935c19.488,-5.61 38.22,-10.5 58.605,-11.616c39.994,-2.184 64.936,-27.529 96.408,-49.582c43.781,-30.674 90.834,-25.063 131.477,8.599c15.75,13.044 30.433,29.204 51.638,31.988c34.023,4.465 69.848,6.437 104.153,7.567' />
          <path d='M0,1524.53c30.143,5.285 64.703,27.614 95.228,17.106c26.611,-9.157 48.332,-29.055 72.314,-43.123c24.992,-14.662 45.052,-19.255 70.002,-1.95c30.914,21.452 55.214,52.309 81.217,79.124c18.767,19.347 43.809,49.399 72.772,52.253c19.396,1.915 39.556,-6.938 56.422,-15.304c42.58,-21.121 80.425,-53.448 129.35,-56.959c13.475,-0.968 26.25,-2.346 38.63,-8.133c8.564,-4 16.047,-9.914 24.512,-14.083c10.189,-5.017 20.767,-7.32 32.002,-8.472c26.744,-2.749 46.579,-11.157 69.48,-25.586c45.469,-28.638 100.662,-73.564 150.583,-26.54c15.559,14.662 27.501,38.609 49.144,45.187c13.1,3.978 30.122,1.943 43.668,2.883c17.361,1.201 34.665,2.586 52.069,2.848' />
          <path d='M0,1494.03c19.848,4.868 37.492,13.885 56.274,21.721c16.103,6.719 27.762,11.553 44.296,4.056c27.819,-12.613 48.423,-37.952 72.568,-55.942c25.105,-18.704 44.636,-22.788 70.737,-3.392c27.268,20.266 49.187,47.583 72.123,72.384c20.011,21.629 45.123,54.295 75.422,61.644c21.99,5.335 47.802,-4.96 67.806,-13.051c44.353,-17.926 85.653,-44.332 134.451,-47.003c11.864,-0.65 23.643,-2.183 33.401,-9.56c6.805,-5.144 11.016,-12.712 17.262,-18.386c9.419,-8.55 20.4,-10.747 32.702,-12.273c27.691,-3.434 49.893,-14.153 74.108,-27.953c40.891,-23.311 107.014,-76.108 146.831,-24.18c11.913,15.538 19.77,40.721 39.781,47.879c11.348,4.063 27.042,1.675 38.899,2.212c15.149,0.685 30.256,1.483 45.427,1.469' />
          <path d='M0,1463.52c21.572,5.964 39.782,17.573 59.743,27.381c16.69,8.203 28.2,13.559 45.364,4.331c29.076,-15.63 48.557,-45.696 73.436,-66.66c21.064,-17.736 38.637,-23.841 62.351,-8.006c32.687,21.82 57.524,55.849 83.767,84.544c19.029,20.81 45.017,54.804 75.443,58.358c20.591,2.403 43.046,-5.052 62.238,-11.326c47.49,-15.524 92.437,-38.771 143.015,-41.605c11.447,-0.643 23.268,-1.993 31.168,-11.256c5.491,-6.437 6.741,-15.256 11.581,-22.06c6.141,-8.642 14.789,-11.518 24.901,-13.115c12.266,-1.943 23.205,-2.324 35.174,-6.613c16.471,-5.9 32.08,-14.217 47.477,-22.442c36.658,-19.587 113.084,-79.153 144.881,-24.632c9.553,16.372 14.096,42.657 33.599,50.621c10.436,4.261 25.218,1.703 36.227,1.922c13.68,0.268 27.338,0.586 41.018,0.466' />
          <path d='M0,1433.02c22.54,5.745 40.559,19.036 60.909,29.819c16.075,8.521 28.596,14.4 45.533,5.228c29.939,-16.209 48.515,-49.575 73.317,-71.988c20.442,-18.47 38.785,-24.045 62.322,-8.274c32.906,22.046 57.722,56.704 83.965,85.788c18.852,20.894 44.968,55.652 75.521,58.853c20.407,2.141 43.244,-5.554 62.435,-11.158c48.162,-14.061 95.483,-35.732 146.111,-38.537c11.362,-0.629 23.741,-1.159 30.687,-11.56c4.805,-7.194 4.042,-16.563 7.992,-24.088c5.059,-9.645 13.651,-12.253 23.862,-13.701c12.33,-1.753 22.971,-2.099 35.005,-6.409c17.078,-6.112 33.351,-14.563 49.44,-22.866c31.96,-16.485 65.629,-39.385 101.404,-46.444c20.753,-4.099 32.857,4.543 41.845,22.908c8.295,16.951 11.341,43.696 31.91,50.86c10.875,3.788 24.886,1.562 36.192,1.562c12.902,0 25.798,0.014 38.7,0' />
          <path d='M0,1402.51c21.933,5.809 39.845,18.167 59.863,28.434c16.471,8.444 28.299,13.807 45.399,4.614c29.246,-15.729 48.409,-46.607 73.218,-67.897c21.558,-18.499 38.368,-23.085 62.477,-7.229c31.734,20.866 56.648,52.176 82.87,79.189c18.675,19.241 46.049,53.15 75.09,56.217c19.743,2.084 43.449,-6.247 62.393,-10.507c47.929,-10.79 96.196,-26.653 145.326,-30.751c11.963,-0.997 23.254,-1.604 31.14,-11.822c5.723,-7.419 6.522,-17.368 11.482,-25.176c6.006,-9.454 14.189,-12.479 24.894,-13.991c30.581,-4.331 55.291,-14.329 83.039,-28.504c31.288,-15.99 63.764,-37.457 98.62,-44.664c20.357,-4.211 32.673,4.791 42.516,22.124c9.37,16.499 14.125,40.77 34.129,48.077c10.903,3.985 24.625,1.886 36.008,1.886l38.679,0' />
          <path d='M0,1372c32.228,7.914 68.314,45.265 101.913,29.034c28.334,-13.686 48.303,-40.926 73.041,-59.672c21.869,-16.577 38.276,-20.258 62.223,-6.543c30.101,17.241 54.924,43.442 80.722,66.237c19.389,17.135 46.176,44.925 73.67,47.681c19.678,1.971 42.332,-3.851 61.926,-5.61c47.858,-4.297 95.73,-9.243 143.623,-13.044c13.101,-1.039 24.583,-2.586 33.981,-12.655c7.447,-7.978 11.15,-18.584 17.933,-27.042c7.547,-9.426 16.676,-13.326 28.37,-15.001c29.19,-4.169 52.755,-12.839 79.379,-26.208c38.581,-19.375 101.828,-67.904 136.967,-21.268c11.426,15.17 18.909,35.916 38.637,42.311c21.749,7.045 52.048,1.787 74.765,1.787' />
          <path d='M0,1341.5c31.839,6.445 64.018,36.701 97.094,23.749c27.303,-10.691 48.197,-33.514 72.794,-48.797c21.452,-13.327 38.559,-16.104 61.686,-6.113c28.15,12.168 52.726,31.967 77.888,49.13c20.88,14.245 45.526,32.652 71.6,35.146c20.152,1.922 40.82,0.212 61.149,2.17c46.904,4.508 94.041,13.729 141.333,11.277c33.133,-1.717 43.294,-22.328 64.711,-43.307c9.652,-9.455 20.208,-14.585 33.507,-16.485c27.246,-3.901 49.129,-10.628 74.207,-22.88c40.043,-19.559 91.278,-56.57 131.37,-19.82c14.09,12.917 24.979,29.734 44.7,34.56c23.056,5.638 51.433,1.37 75.083,1.37' />
          <path d='M0,1311c30.991,4.409 59.566,26.78 91.54,17.806c26.215,-7.363 48.091,-25.212 72.525,-36.574c47.25,-21.975 92.155,4.996 135.702,24.102c22.117,9.702 44.813,19.093 69.183,20.57c20.817,1.264 40.227,5.144 60.224,11.411c44.367,13.906 91.285,39.471 138.748,38.983c35.725,-0.367 54.061,-26.822 79.315,-47.391c11.793,-9.61 24.364,-16.083 39.492,-18.217c24.985,-3.533 45.01,-8.104 68.265,-19.014c41.053,-19.269 81.569,-43.774 125.053,-18.28c16.867,9.893 31.536,22.703 51.554,25.791c24.293,3.752 50.988,0.806 75.535,0.806' />
          <path d='M0,1280.49c29.366,2.19 55.97,16.866 85.951,11.835c25.204,-4.225 48.006,-16.852 72.249,-24.293c42.962,-13.178 88.827,-2.247 131.816,5.088c22.498,3.837 44.142,4.614 66.753,5.921c21.297,1.23 40.77,10.698 59.283,20.718c25.127,13.595 48.869,29.571 73.819,43.47c18.753,10.45 40.339,22.625 62.336,23.353c38.354,1.265 65.41,-31.119 93.992,-51.49c32.991,-23.509 71.578,-19.022 107.805,-35.09c20.944,-9.292 42.198,-21.247 65.332,-23.862c18.259,-2.063 36.136,1.258 53.377,7.13c19.389,6.607 37.669,15.46 58.436,16.987c25.084,1.844 50.839,0.226 75.987,0.226' />
          <path d='M0,1249.99c27.331,0.396 53.475,8.423 81.047,6.472c24.427,-1.731 47.936,-9.299 72.009,-13.255c19.707,-3.237 39.867,-2.926 59.757,-5.095c22.852,-2.494 45.746,-4.572 68.632,-6.762c20.781,-1.986 43.852,-7.653 64.64,-6.861c21.791,0.834 41.831,16.11 58.492,28.666c24.901,18.761 47.674,40.149 72.299,59.249c17.284,13.411 38.743,30.829 61.531,32.305c40.523,2.629 75.839,-34.708 107.014,-55.157c15.404,-10.104 32.249,-19.007 50.755,-21.473c20.485,-2.728 37.45,-3.83 57.023,-11.737c18.647,-7.532 36.595,-16.555 56.902,-18.59c18.577,-1.866 37.846,0.247 56.132,3.321c21.509,3.617 42.658,8.797 64.591,9.115c25.416,0.367 50.896,-0.205 76.319,-0.205' />
          <path d='M0,1219.48c25.854,-0.466 51.652,2.544 77.549,2.332c24.017,-0.198 47.865,-3.413 71.819,-4.756c20.392,-1.144 39.421,0.177 59.474,-4.43c22.569,-5.186 44.417,-13.016 66.413,-20.138c19.029,-6.162 42.842,-16.358 63.156,-15.807c22.562,0.608 42.467,19.545 57.998,33.861c24.759,22.823 46.713,48.479 71.105,71.677c16.238,15.439 37.238,36.531 60.958,38.255c42.403,3.081 83.259,-37.315 116.66,-57.948c16.626,-10.274 34.75,-19.884 54.401,-22.533c18.795,-2.537 35.047,-2.629 53.207,-9.335c16.746,-6.182 32.531,-13.079 50.55,-14.619c19.148,-1.639 39.046,-0.495 58.167,0.636c48.649,2.883 96.952,2.805 145.679,2.805' />
          <path d='M0,1188.98c49.292,-0.092 98.585,0 147.877,-0.042c19.891,-0.014 40.156,1.611 59.609,-3.399c22.915,-5.9 43.901,-17.524 65.197,-27.494c18.923,-8.86 41.16,-19.594 62.633,-18.972c23.657,0.686 42.262,18.972 57.949,34.878c24.787,25.134 46.07,53.461 70.398,79.026c15.877,16.683 36.072,38.594 60.739,39.689c43.845,1.944 86.05,-38.354 121.203,-59.417c17.064,-10.225 35.62,-20.209 55.645,-22.887c17.968,-2.403 34.15,-2.445 51.581,-8.43c15.715,-5.398 30.589,-11.214 47.342,-12.613c19.481,-1.632 39.598,-0.374 59.122,-0.353c49.285,0.057 98.57,0.028 147.855,0.028' />
          <path d='M0,1158.48l147.87,0c19.997,0 40.057,1.308 59.623,-3.568c22.724,-5.66 43.809,-16.379 65.176,-25.671c18.725,-8.147 41.647,-19.021 62.633,-18.103c22.944,1.004 42.198,19.114 57.864,34.065c24.349,23.247 45.618,49.476 69.564,73.119c15.913,15.708 36.75,37.125 60.584,38.39c42.396,2.254 86.064,-36.32 120.899,-55.737c17.269,-9.631 35.761,-18.887 55.525,-21.904c17.827,-2.721 34.588,-3.053 52.062,-8.388c16.061,-4.904 31.578,-10.337 48.451,-11.765c19.46,-1.653 39.499,-0.438 59.001,-0.438l147.87,0' />
          <path d='M0,1127.97l147.87,0c19.983,0 39.958,1.089 59.623,-3.123c22.455,-4.805 43.71,-13.899 65.169,-21.819c19.149,-7.066 41.81,-16.337 62.633,-15.482c21.905,0.897 41.054,15.905 57.221,29.281c23.664,19.594 44.734,42.085 68.066,62.054c16.485,14.118 37.068,31.818 59.842,32.927c41.484,2.028 84.304,-30.398 120.03,-47.469c17.467,-8.345 35.86,-16.11 55.199,-18.739c18.139,-2.459 35.655,-2.869 53.596,-7.228c37.301,-9.066 71.72,-10.409 110.003,-10.409l147.87,0' />
          <path d='M0,1097.47l147.87,-0c19.898,-0 39.902,0.932 59.623,-2.247c22.194,-3.583 43.611,-10.67 65.169,-16.832c19.905,-5.688 41.697,-12.33 62.633,-11.772c20.725,0.552 39.259,10.875 56.224,21.99c36.998,24.236 77.797,71.31 124.913,72.893c40.947,1.377 81.612,-22.534 118.857,-36.447c17.651,-6.592 35.881,-12.415 54.726,-14.231c18.753,-1.809 37.11,-2.134 55.757,-5.384c85.52,-14.916 174.771,-7.963 261.35,-7.963' />
          <path d='M0,1066.96l147.87,-0c43.032,-0 82.531,-4.367 124.792,-12.663c41.562,-8.161 78.334,-11.892 117.741,6.127c39.032,17.848 77.047,48.225 121.634,48.797c40.361,0.523 79.033,-14.485 117.578,-24.434c37.329,-9.631 74.362,-8.324 112.363,-12.542c87.731,-9.737 176.975,-5.285 265.151,-5.285' />
          <path d='M0,1036.45c90.996,-0 182.14,3.434 272.662,-6.685c38.906,-4.352 78.461,-7.525 116.723,2.431c39.803,10.359 76.793,26.519 118.575,26.278c39.491,-0.226 77.443,-7.539 116.376,-13.192c37.98,-5.511 75.896,-3.787 114.102,-6.041c89.363,-5.264 179.2,-2.791 268.698,-2.791' />
          <path d='M0,1005.95c90.869,-0 181.871,1.781 272.662,-2.141c38.376,-1.661 77.726,-3.575 116.024,0.049c39.075,3.703 76.864,9.426 116.214,8.889c38.63,-0.522 76.885,-2.381 115.465,-4.501c38.453,-2.105 76.892,-0.579 115.381,-1.406c90.43,-1.943 180.939,-0.897 271.39,-0.897' />
          <path d='M0,975.327c335.712,0.148 671.424,0 1007.14,0' />
          <path d='M0,944.823c91.222,0.255 182.507,-1.745 273.673,1.689c77.061,2.904 153.678,-4.869 230.591,-3.01c76.581,1.851 153.261,-4.226 229.821,-0.502c90.805,4.423 182.133,1.823 273.044,1.823' />
          <path d='M0,914.319c92.056,0.021 184.111,-3.116 275.856,5.335c38.743,3.568 78.433,5.293 117.175,0.862c37.888,-4.338 74.603,-11.567 112.922,-10.62c75.754,1.872 151.749,-10.507 227.306,-1.335c90.409,10.98 182.889,5.751 273.877,5.751' />
          <path d='M0,883.815c49.066,-0.296 97.977,-0.883 147.071,-0.226c44.947,0.601 87.343,3.71 131.859,10.684c39.47,6.183 79.125,9.504 118.489,1.039c37.323,-8.027 72.285,-20.725 110.958,-20.343c74.751,0.735 149.565,-18.103 223.716,-2.431c22.286,4.713 44.664,10.698 67.565,11.348c19.82,0.565 39.781,-0.078 59.608,-0.078l147.87,0' />
          <path d='M0,853.312c48.925,-0.749 97.426,-1.392 146.421,-0.403c20.612,0.417 41.471,-0.594 61.969,1.908c25.063,3.059 49.589,9.532 74.186,15.05c40.855,9.165 79.669,14.74 120.058,1.124c36.411,-12.274 69.282,-31.204 108.64,-31.811c74.058,-1.138 146.555,-26.279 219.441,-3.696c22.505,6.974 45.166,15.771 68.872,17.552c19.7,1.477 39.944,0.283 59.679,0.283l147.87,-0' />
          <path d='M0,822.808c22.025,-0.587 43.59,-3.3 65.7,-2.926c26.674,0.453 53.334,1.597 80.008,2.318c21.12,0.572 42.41,-0.438 63.311,3.144c26.349,4.523 51.85,13.122 77.471,20.541c42.516,12.309 80.411,20.732 121.747,1.173c35.097,-16.605 65.855,-42.233 106.153,-44.085c34.588,-1.589 67.261,-8.337 101.234,-14.428c15.814,-2.834 31.846,-5.116 47.936,-5.54c23.416,-0.615 44.19,6.133 65.664,14.93c22.696,9.299 45.639,21.057 70.264,24.166c19.481,2.459 40.191,0.7 59.771,0.7l147.87,-0' />
          <path d='M0,792.297c21.17,-0.813 41.47,-4.204 62.802,-3.738c27.417,0.6 54.804,2.021 82.206,2.932c21.672,0.721 43.322,-0.268 64.626,4.367c27.635,6.013 54.097,16.697 80.736,25.982c23.211,8.09 48.642,17.954 73.578,18.272c17.983,0.226 34.892,-7.758 49.843,-17.043c33.345,-20.703 62.442,-53.094 103.686,-56.231c33.797,-2.572 64.569,-10.684 97.412,-18.47c15.157,-3.597 30.758,-6.614 46.374,-7.165c24.208,-0.862 45.244,8.549 66.491,19.262c22.859,11.524 46.056,26.228 71.649,30.722c19.199,3.371 40.467,1.11 59.856,1.11l147.87,-0' />
          <path d='M0,761.793c20.428,-1.003 39.449,-5.017 60.153,-4.48c28.094,0.735 56.153,2.41 84.233,3.505c22.18,0.862 44.106,-0.142 65.763,5.405c28.837,7.384 56.175,20.004 83.739,31.006c23.396,9.334 50.12,21.636 75.804,21.989c18.252,0.255 35.118,-9.991 49.13,-20.654c31.613,-24.059 59.164,-63.085 101.447,-67.331c33.231,-3.336 62.081,-12.733 93.928,-22.145c14.542,-4.296 29.748,-7.957 44.968,-8.635c24.943,-1.116 46.296,10.853 67.247,23.169c22.964,13.511 46.402,31.006 72.921,36.729c18.915,4.085 40.721,1.435 59.926,1.435l147.87,-0' />
          <path d='M0,731.289c19.848,-1.123 37.725,-5.674 57.991,-5.08c28.666,0.84 57.291,2.734 85.936,3.978c22.526,0.982 44.685,-0.113 66.569,6.105c29.868,8.486 57.934,22.773 86.268,35.224c23.544,10.344 51.229,24.561 77.578,24.95c18.633,0.268 35.252,-11.638 48.586,-23.403c30.122,-26.589 56.273,-71.571 99.609,-76.447c32.977,-3.709 60.011,-14.28 91.073,-25.133c14.04,-4.904 28.914,-9.017 43.859,-9.794c25.621,-1.328 47.179,12.691 67.869,26.286c22.999,15.121 46.649,35.118 73.973,41.71c18.697,4.508 40.905,1.611 59.962,1.611l147.87,0' />
          <path d='M0,700.785c19.46,-1.144 36.517,-6.105 56.528,-5.49c29.076,0.897 58.111,2.968 87.166,4.324c22.597,1.053 44.982,-0.254 66.872,6.296c30.653,9.172 59.227,24.766 88.092,38.269c23.721,11.094 51.765,26.371 78.673,26.774c19.078,0.282 35.259,-12.211 48.275,-24.873c29.055,-28.271 53.998,-77.931 98.365,-82.651c33.069,-3.526 58.563,-15.156 89.145,-27.126c13.75,-5.384 28.348,-9.673 43.18,-10.5c26.243,-1.463 47.716,13.666 68.307,28.236c22.901,16.202 46.77,38.326 74.708,45.194c18.591,4.564 40.99,1.554 59.948,1.554l147.87,0' />
          <path d='M0,670.281c8.592,-0.409 14.874,-2.579 23.007,-4.501c10.747,-2.536 22.152,-1.582 33.09,-1.257c29.232,0.862 58.4,3.215 87.611,4.649c22.385,1.102 45.01,-0.657 66.71,5.815c31.055,9.271 59.814,25.763 88.834,39.838c23.911,11.596 51.588,26.844 78.913,27.19c19.523,0.248 35.125,-11.425 48.303,-24.816c28.68,-29.147 52.457,-81.117 97.821,-85.039c33.217,-2.868 58.273,-15.276 88.671,-27.55c13.694,-5.533 28.208,-9.822 43.039,-10.641c26.49,-1.477 47.844,13.778 68.434,28.716c22.724,16.492 46.565,39.273 74.638,45.992c18.852,4.516 41.732,1.067 60.958,1.166c49.13,0.262 98.26,0.629 147.39,0.438' />
          <path d='M0,639.777c9.68,-0.466 15.164,-3.631 24.003,-6.401c10.557,-3.314 21.99,-2.297 32.914,-1.887c28.624,1.06 57.086,4.657 85.632,6.769c26.045,1.929 51.278,-0.141 76.172,9.115c26.787,9.956 51.631,24.745 76.842,38.015c23.919,12.592 50.96,28.49 78.659,29.953c22.915,1.208 39.859,-13.03 54.281,-29.267c26.9,-30.299 48.105,-77.648 92.451,-82.757c31.528,-3.632 58.838,-12.443 88.812,-22.3c15.962,-5.25 33.154,-10.281 50.091,-10.62c24.371,-0.488 45.696,13.305 65.466,26.144c19.1,12.401 38.1,26.815 60.005,33.952c23.84,7.765 52.557,1.583 77.528,2.967c51.617,2.862 103.043,6.317 154.801,6.056' />
          <path d='M0,609.274c10.995,-1.06 16.019,-5.547 25.628,-9.766c10.599,-4.656 20.796,-4.07 32.214,-3.398c27.353,1.611 54.38,6.719 81.542,10.076c24.434,3.017 49.942,0.636 73.26,9.334c29.776,11.108 56.577,29.677 83.845,45.653c22.477,13.171 48.494,30.052 75.323,31.578c22.258,1.272 38.573,-13.453 51.956,-29.536c24.915,-29.959 46.191,-83.279 90.261,-86.94c32.878,-2.727 64.011,-8.733 96.338,-15.191c17.022,-3.399 34.602,-6.706 52.013,-6.982c20.724,-0.325 39.159,6.833 57.729,15.411c18.675,8.628 36.969,18.676 57.602,21.771c14.245,2.134 28.85,-0.778 43.187,-0.87c14.93,-0.091 29.833,1.025 44.643,2.883c25.339,3.173 50.501,9.469 76.079,10.423c32.334,1.208 64.675,4.253 97.023,4.776' />
          <path d='M0,578.77c16.273,-2.643 25.805,-17.029 41.64,-19.707c19.191,-3.244 42.594,3.695 61.311,7.44c18.888,3.788 37.712,8.133 56.846,10.543c22.138,2.791 40.912,3.102 61.383,13.305c42.982,21.417 78.545,56.379 122.983,75.027c37.478,15.728 60.661,-3.724 81.633,-34.179c17.75,-25.776 34.744,-67.077 66.392,-78.686c13.207,-4.848 29.911,-2.53 43.866,-3.166c14.153,-0.643 28.285,-1.696 42.41,-2.833c27.812,-2.24 55.602,-4.749 83.52,-5.215c25.021,-0.417 48.748,3.462 73.486,6.055c20.569,2.155 40.269,-0.713 60.853,-1.476c29.917,-1.117 59.234,2.89 88.36,9.461c59.255,13.369 119.316,19.502 180.161,22.01' />
          <path d='M0,548.266c17.806,-3.929 26.985,-21.566 44.127,-25.572c19.135,-4.473 44.707,5.088 63.029,10.034c17.785,4.798 35.52,10.168 53.708,13.263c21.474,3.653 39.315,3.872 58.924,14.796c43.067,23.996 77.308,62.583 122.22,83.414c38.184,17.707 60.831,-6.741 80.072,-38.135c15.919,-25.982 31.358,-67.664 62.11,-79.542c13.277,-5.123 29.995,-2.268 44.014,-1.915c15.008,0.374 30.023,0.763 45.031,1.152c30.61,0.791 61.227,1.597 91.837,2.339c23.091,0.565 40.643,-5.766 61.156,-15.058c48.31,-21.883 107.565,-12.599 156.935,1.13c24.357,6.777 48.749,16.867 74.101,19.206c44.453,4.105 88.99,10.168 133.548,12.825' />
          <path d='M0,517.762c20.032,-5.087 30.327,-27.614 50.444,-30.567c18.202,-2.671 39.683,5.992 56.705,11.56c18.392,6.02 36.651,12.902 55.708,16.583c21.551,4.169 38.029,4.565 57.581,16.634c41.83,25.819 74.327,65.826 118.284,88.289c13.687,6.996 28.215,11.631 42.714,4.388c16.217,-8.097 26.526,-25.706 35.238,-40.855c15.446,-26.851 29.713,-71.819 61.17,-84.411c13.249,-5.306 30.165,-2.049 44.106,-1.052c16.98,1.215 33.917,2.875 50.854,4.578c31.91,3.222 63.947,7.257 96.027,8.303c28.009,0.911 34.362,-11.602 49.116,-31.733c13.128,-17.913 32.8,-19.128 53.743,-20.153c39.542,-1.936 78.058,4.819 115.593,17.058c24.865,8.111 49.836,20.279 76.101,23.006c49.122,5.102 98.358,12.422 147.636,15.807' />
          <path d='M0,487.251c20.873,-5.144 30.56,-29.373 51.37,-32.751c18.011,-2.925 39.188,5.999 55.786,12.034c17.968,6.529 35.754,14.096 54.507,18.096c20.887,4.458 38.121,4.133 57.001,15.827c42.05,26.046 73.14,67.24 116.426,91.448c38.821,21.707 61.898,-2.621 80.341,-36.616c14.697,-27.083 28.03,-72.652 59.439,-85.194c13.001,-5.194 30.207,-2.063 43.837,-0.883c17.432,1.505 34.772,3.858 52.09,6.31c32.589,4.607 65.219,10.054 98.154,11.39c12.21,0.495 25.459,1.236 33.641,-9.391c6.911,-8.967 7.172,-21.452 11.701,-31.521c10.168,-22.597 35.224,-21.799 57.327,-23.014c38.983,-2.134 76.871,4.31 113.677,16.958c26.307,9.038 53.031,24.745 81.111,27.515c51.143,5.045 102.252,13.631 153.544,17.015' />
          <path d='M0,456.747c19.764,-5.723 32.468,-27.253 52.493,-30.391c17.397,-2.727 39.118,5.915 55.68,10.755c18.04,5.271 35.959,11.15 54.401,14.867c20.788,4.197 38.46,4.557 57.023,16.16c42.276,26.419 73.034,68.589 116.624,93.08c38.29,21.516 61.594,-3.738 80.884,-36.291c15.849,-26.759 30.589,-70.031 61.637,-82.997c13.121,-5.483 30.186,-2.092 44.007,-0.961c16.859,1.385 33.662,3.356 50.444,5.412c31.726,3.887 63.445,8.246 95.391,9.872c12.747,0.65 25.034,1.427 33.902,-9.108c7.73,-9.179 9.638,-22.075 15.128,-32.483c11.483,-21.791 33.924,-21.325 56.747,-22.441c37.825,-1.852 74.299,5.094 109.763,17.94c26.328,9.532 52.812,25.063 81.182,27.452c50.578,4.246 100.796,12.944 151.445,16.442' />
          <path d='M0,426.243c19.58,-5.031 36.072,-21.614 55.991,-23.925c17.679,-2.049 38.502,3.985 56.019,6.741c36.192,5.688 81.139,4.451 112.229,25.134c42.149,28.037 72.978,72.157 117.755,96.337c35.973,19.425 60.188,-5.356 80.439,-34.185c18.16,-25.847 35.683,-66.187 67.07,-78.651c13.341,-5.3 29.706,-2.247 43.746,-1.414c15.707,0.933 31.394,2.127 47.08,3.357c29.819,2.346 59.652,5.002 89.548,6.175c12.598,0.495 23.572,0.608 33.302,-8.486c9.864,-9.221 14.761,-22.54 22.307,-33.479c13.821,-20.032 31.868,-21.77 55.341,-22.858c38.064,-1.767 74.171,6.776 109.063,21.622c23.24,9.885 46.6,24.003 72.25,26.208c48.247,4.147 96.309,11.722 144.641,14.965' />
          <path d='M0,395.74c19.347,-3.18 37.697,-11.85 57.086,-14.309c19.665,-2.494 41.174,0.558 61.086,0.113c36.764,-0.827 77.556,-6.875 109.43,14.789c43.343,29.465 74.101,76.214 120.165,101.821c35.542,19.756 62.604,-5.766 85.18,-32.249c20.407,-23.933 40.955,-58.966 72.186,-69.869c13.447,-4.692 29.147,-2.226 43.202,-1.985c13.185,0.226 26.37,0.339 39.562,0.431c27.112,0.183 54.217,0.311 81.323,0.961c15.319,0.367 26.85,-0.615 39.11,-10.896c11.369,-9.539 19.488,-22.413 29.493,-33.253c38.1,-41.279 108.64,-21.042 152.442,0.87c21.346,10.676 42.869,26.052 67.204,28.61c44.615,4.684 89.738,10.189 134.53,12.881' />
          <path d='M0,365.236c42.028,-1.88 84.121,-1.894 125.238,-11.426c37.16,-8.613 72.843,-19.841 106.597,3.618c43.42,30.179 73.606,76.729 118.398,105.092c35.365,22.392 65.134,1.696 92.274,-23.261c24.124,-22.187 48.445,-53.899 81.238,-63.269c23.353,-6.67 52.154,-2.954 76.588,-5.547c22.788,-2.416 45.463,-4.896 68.413,-5.214c16.782,-0.234 29.6,-2.035 43.661,-11.751c14.641,-10.119 26.575,-23.601 40.057,-35.118c40.884,-34.927 97.313,-23.226 139.998,3.229c20.576,12.754 39.499,29.253 64.364,31.783c38.92,3.964 78.327,8.309 117.402,10.203' />
          <path d='M0,334.732c23.353,1.738 46.473,7.963 69.854,8.776c20.463,0.706 40.064,-7.879 58.372,-16.04c17.086,-7.61 33.938,-16.294 52.091,-21.141c21.636,-5.78 37.817,-1.661 55.998,11.39c44.593,32.03 74.793,81.329 120.941,111.332c33.868,22.017 65.537,4.508 95.137,-16.563c28.087,-19.99 56.372,-48.275 91.052,-55.97c23.07,-5.116 46.522,-3.264 69.685,-9.935c19.488,-5.61 38.22,-10.5 58.605,-11.616c39.994,-2.183 64.936,-27.529 96.408,-49.582c43.781,-30.674 90.834,-25.063 131.477,8.599c15.75,13.044 30.433,29.204 51.638,31.988c34.023,4.466 69.848,6.437 104.153,7.568' />
          <path d='M0,304.228c30.143,5.285 64.703,27.614 95.228,17.107c26.611,-9.158 48.332,-29.056 72.314,-43.124c24.992,-14.662 45.052,-19.255 70.002,-1.95c30.914,21.452 55.214,52.309 81.217,79.125c18.767,19.346 43.809,49.398 72.772,52.253c19.396,1.915 39.556,-6.939 56.422,-15.305c42.58,-21.121 80.425,-53.447 129.35,-56.959c13.475,-0.968 26.25,-2.346 38.63,-8.133c8.564,-4 16.047,-9.914 24.512,-14.083c10.189,-5.017 20.767,-7.32 32.002,-8.472c26.744,-2.748 46.579,-11.157 69.48,-25.586c45.469,-28.638 100.662,-73.564 150.583,-26.54c15.559,14.662 27.501,38.609 49.144,45.187c13.1,3.979 30.122,1.944 43.668,2.883c17.361,1.202 34.665,2.586 52.069,2.848' />
          <path d='M0,273.724c19.848,4.869 37.492,13.885 56.274,21.721c16.103,6.72 27.762,11.553 44.296,4.056c27.819,-12.613 48.423,-37.952 72.568,-55.942c25.105,-18.703 44.636,-22.787 70.737,-3.391c27.268,20.265 49.187,47.582 72.123,72.384c20.011,21.629 45.123,54.295 75.422,61.643c21.99,5.335 47.802,-4.96 67.806,-13.051c44.353,-17.926 85.653,-44.332 134.451,-47.003c11.864,-0.65 23.643,-2.183 33.401,-9.56c6.805,-5.144 11.016,-12.711 17.262,-18.385c9.419,-8.55 20.4,-10.748 32.702,-12.274c27.691,-3.434 49.893,-14.153 74.108,-27.953c40.891,-23.311 107.014,-76.108 146.831,-24.18c11.913,15.538 19.77,40.721 39.781,47.879c11.348,4.063 27.042,1.675 38.899,2.212c15.149,0.685 30.256,1.484 45.427,1.47' />
          <path d='M0,243.22c21.572,5.964 39.782,17.573 59.743,27.381c16.69,8.203 28.2,13.56 45.364,4.331c29.076,-15.63 48.557,-45.695 73.436,-66.66c21.064,-17.736 38.637,-23.841 62.351,-8.006c32.687,21.82 57.524,55.85 83.767,84.545c19.029,20.809 45.017,54.803 75.443,58.358c20.591,2.402 43.046,-5.053 62.238,-11.327c47.49,-15.524 92.437,-38.771 143.015,-41.605c11.447,-0.643 23.268,-1.992 31.168,-11.256c5.491,-6.437 6.741,-15.255 11.581,-22.06c6.141,-8.642 14.789,-11.517 24.901,-13.114c12.266,-1.943 23.205,-2.325 35.174,-6.614c16.471,-5.9 32.08,-14.217 47.477,-22.442c36.658,-19.587 113.084,-79.153 144.881,-24.632c9.553,16.372 14.096,42.658 33.599,50.621c10.436,4.261 25.218,1.703 36.227,1.922c13.68,0.268 27.338,0.586 41.018,0.466' />
          <path d='M0,212.716c22.54,5.745 40.559,19.036 60.909,29.819c16.075,8.521 28.596,14.4 45.533,5.229c29.939,-16.21 48.515,-49.575 73.317,-71.989c20.442,-18.47 38.785,-24.045 62.322,-8.274c32.906,22.046 57.722,56.705 83.965,85.788c18.852,20.894 44.968,55.652 75.521,58.853c20.407,2.141 43.244,-5.554 62.435,-11.157c48.162,-14.062 95.483,-35.733 146.111,-38.538c11.362,-0.629 23.741,-1.159 30.687,-11.56c4.805,-7.193 4.042,-16.563 7.992,-24.088c5.059,-9.645 13.651,-12.253 23.862,-13.701c12.33,-1.752 22.971,-2.099 35.005,-6.409c17.078,-6.112 33.351,-14.563 49.44,-22.865c31.96,-16.485 65.629,-39.386 101.404,-46.445c20.753,-4.098 32.857,4.543 41.845,22.908c8.295,16.951 11.341,43.696 31.91,50.861c10.875,3.787 24.886,1.561 36.192,1.561c12.902,0 25.798,0.014 38.7,0' />
          <path d='M0,182.212c21.933,5.809 39.845,18.167 59.863,28.434c16.471,8.444 28.299,13.807 45.399,4.614c29.246,-15.729 48.409,-46.607 73.218,-67.897c21.558,-18.499 38.368,-23.085 62.477,-7.228c31.734,20.865 56.648,52.175 82.87,79.188c18.675,19.241 46.049,53.15 75.09,56.217c19.743,2.084 43.449,-6.246 62.393,-10.507c47.929,-10.79 96.196,-26.653 145.326,-30.751c11.963,-0.997 23.254,-1.604 31.14,-11.822c5.723,-7.419 6.522,-17.368 11.482,-25.176c6.006,-9.454 14.189,-12.478 24.894,-13.99c30.581,-4.332 55.291,-14.33 83.039,-28.505c31.288,-15.99 63.764,-37.456 98.62,-44.664c20.357,-4.211 32.673,4.791 42.516,22.124c9.37,16.499 14.125,40.771 34.129,48.077c10.903,3.985 24.625,1.886 36.008,1.886l38.679,0' />
          <path d='M0,151.702c32.228,7.913 68.314,45.264 101.913,29.034c28.334,-13.687 48.303,-40.927 73.041,-59.673c21.869,-16.576 38.276,-20.258 62.223,-6.543c30.101,17.241 54.924,43.442 80.722,66.237c19.389,17.135 46.176,44.925 73.67,47.681c19.678,1.972 42.332,-3.851 61.926,-5.61c47.858,-4.296 95.73,-9.243 143.623,-13.044c13.101,-1.039 24.583,-2.586 33.981,-12.655c7.447,-7.978 11.15,-18.584 17.933,-27.042c7.547,-9.426 16.676,-13.326 28.37,-15.001c29.19,-4.169 52.755,-12.839 79.379,-26.208c38.581,-19.375 101.828,-67.904 136.967,-21.268c11.426,15.17 18.909,35.916 38.637,42.311c21.749,7.045 52.048,1.788 74.765,1.788' />
          <path d='M0,121.198c31.839,6.444 64.018,36.7 97.094,23.748c27.303,-10.69 48.197,-33.514 72.794,-48.797c21.452,-13.327 38.559,-16.104 61.686,-6.112c28.15,12.167 52.726,31.966 77.888,49.129c20.88,14.246 45.526,32.652 71.6,35.147c20.152,1.922 40.82,0.212 61.149,2.169c46.904,4.508 94.041,13.729 141.333,11.277c33.133,-1.717 43.294,-22.328 64.711,-43.307c9.652,-9.454 20.208,-14.584 33.507,-16.485c27.246,-3.901 49.129,-10.627 74.207,-22.88c40.043,-19.558 91.278,-56.57 131.37,-19.82c14.09,12.917 24.979,29.734 44.7,34.56c23.056,5.639 51.433,1.371 75.083,1.371' />
          <path d='M0,90.694c30.991,4.409 59.566,26.78 91.54,17.806c26.215,-7.363 48.091,-25.211 72.525,-36.573c47.25,-21.976 92.155,4.995 135.702,24.102c22.117,9.701 44.813,19.092 69.183,20.569c20.817,1.265 40.227,5.144 60.224,11.411c44.367,13.906 91.285,39.471 138.748,38.983c35.725,-0.367 54.061,-26.822 79.315,-47.391c11.793,-9.61 24.364,-16.082 39.492,-18.216c24.985,-3.533 45.01,-8.105 68.265,-19.015c41.053,-19.269 81.569,-43.774 125.053,-18.28c16.867,9.893 31.536,22.703 51.554,25.791c24.293,3.752 50.988,0.806 75.535,0.806' />
          <path d='M0,60.19c29.366,2.19 55.97,16.866 85.951,11.835c25.204,-4.225 48.006,-16.852 72.249,-24.292c42.962,-13.178 88.827,-2.247 131.816,5.087c22.498,3.837 44.142,4.614 66.753,5.921c21.297,1.23 40.77,10.698 59.283,20.718c25.127,13.595 48.869,29.571 73.819,43.47c18.753,10.45 40.339,22.625 62.336,23.353c38.354,1.265 65.41,-31.119 93.992,-51.49c32.991,-23.508 71.578,-19.022 107.805,-35.09c20.944,-9.291 42.198,-21.247 65.332,-23.861c18.259,-2.064 36.136,1.257 53.377,7.129c19.389,6.607 37.669,15.461 58.436,16.987c25.084,1.844 50.839,0.226 75.987,0.226' />
          <path d='M0,29.686c27.331,0.396 53.475,8.423 81.047,6.473c24.427,-1.732 47.936,-9.299 72.009,-13.256c19.707,-3.236 39.867,-2.926 59.757,-5.095c22.852,-2.494 45.746,-4.572 68.632,-6.762c20.781,-1.986 43.852,-7.652 64.64,-6.861c21.791,0.834 41.831,16.11 58.492,28.667c24.901,18.76 47.674,40.149 72.299,59.248c17.284,13.411 38.743,30.829 61.531,32.306c40.523,2.628 75.839,-34.708 107.014,-55.157c15.404,-10.105 32.249,-19.008 50.755,-21.474c20.485,-2.727 37.45,-3.83 57.023,-11.737c18.647,-7.532 36.595,-16.555 56.902,-18.59c18.577,-1.866 37.846,0.247 56.132,3.321c21.509,3.618 42.658,8.797 64.591,9.115c25.416,0.367 50.896,-0.205 76.319,-0.205' />
          <path d='M0,-0.818c25.854,-0.466 51.652,2.544 77.549,2.332c24.017,-0.198 47.865,-3.413 71.819,-4.755c20.392,-1.145 39.421,0.176 59.474,-4.431c22.569,-5.186 44.417,-13.015 66.413,-20.138c19.029,-6.161 42.842,-16.358 63.156,-15.806c22.562,0.607 42.467,19.544 57.998,33.86c24.759,22.823 46.713,48.479 71.105,71.677c16.238,15.439 37.238,36.531 60.958,38.255c42.403,3.081 83.259,-37.315 116.66,-57.948c16.626,-10.274 34.75,-19.883 54.401,-22.533c18.795,-2.537 35.047,-2.629 53.207,-9.334c16.746,-6.183 32.531,-13.08 50.55,-14.62c19.148,-1.639 39.046,-0.495 58.167,0.636c48.649,2.883 96.952,2.805 145.679,2.805' />
          <path d='M0,-31.322c49.292,-0.091 98.585,0 147.877,-0.042c19.891,-0.014 40.156,1.611 59.609,-3.399c22.915,-5.9 43.901,-17.523 65.197,-27.494c18.923,-8.86 41.16,-19.594 62.633,-18.972c23.657,0.686 42.262,18.972 57.949,34.878c24.787,25.134 46.07,53.461 70.398,79.026c15.877,16.683 36.072,38.594 60.739,39.69c43.845,1.943 86.05,-38.355 121.203,-59.418c17.064,-10.225 35.62,-20.209 55.645,-22.887c17.968,-2.402 34.15,-2.445 51.581,-8.43c15.715,-5.398 30.589,-11.213 47.342,-12.612c19.481,-1.633 39.598,-0.375 59.122,-0.354c49.285,0.057 98.57,0.028 147.855,0.028' />
          <path d='M0,-61.825l147.87,-0c19.997,-0 40.057,1.307 59.623,-3.569c22.724,-5.66 43.809,-16.379 65.176,-25.671c18.725,-8.147 41.647,-19.021 62.633,-18.103c22.944,1.004 42.198,19.114 57.864,34.066c24.349,23.247 45.618,49.476 69.564,73.118c15.913,15.708 36.75,37.125 60.584,38.39c42.396,2.254 86.064,-36.319 120.899,-55.737c17.269,-9.631 35.761,-18.887 55.525,-21.904c17.827,-2.721 34.588,-3.053 52.062,-8.388c16.061,-4.903 31.578,-10.337 48.451,-11.764c19.46,-1.654 39.499,-0.438 59.001,-0.438l147.87,-0' />
          <path d='M0,-92.329l147.87,-0c19.983,-0 39.958,1.088 59.623,-3.124c22.455,-4.804 43.71,-13.898 65.169,-21.819c19.149,-7.066 41.81,-16.337 62.633,-15.482c21.905,0.898 41.054,15.906 57.221,29.282c23.664,19.594 44.734,42.085 68.066,62.053c16.485,14.118 37.068,31.818 59.842,32.928c41.484,2.028 84.304,-30.398 120.03,-47.47c17.467,-8.345 35.86,-16.11 55.199,-18.739c18.139,-2.459 35.655,-2.869 53.596,-7.228c37.301,-9.066 71.72,-10.408 110.003,-10.408l147.87,-0' />
        </g>
      </g>
    </svg>
  )
}
