import { Field, Form, Formik, FormikErrors, useField } from 'formik'
import { InputHTMLAttributes, useState } from 'react'

type FormValues = {
  firstName: string
  lastName: string
  email: string
  phone: string
  website: string
  industry: string
  budget: string
  message: string
  newsletter: boolean
}

const initialValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  website: '',
  industry: '',
  budget: '',
  message: '',
  newsletter: false,
}

const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const phoneRegex =
  /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/

const websiteRegex =
  /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/

const validate = (values: FormValues) => {
  const errors: FormikErrors<FormValues> = {}

  if (!values.firstName) {
    errors.firstName = 'Please enter your first name.'
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter your last name.'
  }

  if (!values.email) {
    errors.email = 'Please enter your email.'
  } else if (!emailRegex.test(values.email)) {
    errors.email = 'Please enter a valid email address.'
  }

  if (!values.phone.trim()) {
    errors.phone = 'Please enter your phone number.'
  } else if (!phoneRegex.test(values.phone.replaceAll(' ', ''))) {
    errors.phone = 'Please enter a valid phone number (including the international prefix).'
  }

  if (!values.website) {
    errors.website = "Please enter your company's website."
  } else if (!websiteRegex.test(values.website)) {
    errors.website = 'Invalid website URL.'
  }

  if (!values.industry) {
    errors.industry = 'Please enter your company industry.'
  }

  if (!values.budget) {
    errors.budget = 'Please enter a monthly media budget.'
  }

  return errors
}

export function ContactForm() {
  const [formSubmitted, setFormSubmitted] = useState(false)

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const response = await fetch('/api/message', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values),
          })
          setFormSubmitted(true)
          setSubmitting(false)
        } catch (e) {
          console.log(e)
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form
          noValidate
          onSubmit={handleSubmit}
          className='bg-light border-lagoon-300 border-2 text-black flex flex-col gap-8 p-6 rounded-2xl'
        >
          <div className='flex gap-4'>
            <InputField
              label='First Name *'
              autoComplete='given-name'
              name='firstName'
              placeholder='John'
            />
            <InputField
              label='Last Name *'
              autoComplete='family-name'
              name='lastName'
              placeholder='Doe'
            />
          </div>
          <InputField
            label='Company Email *'
            autoComplete='email'
            name='email'
            type='email'
            placeholder='johndoe@company.com'
          />
          <InputField
            label='Phone Number *'
            autoComplete='tel'
            type='tel'
            name='phone'
            placeholder='+XX 1234 567 890'
          />
          <InputField
            label='Company Website *'
            autoComplete='off'
            name='website'
            type='url'
            placeholder='company.com or www.company.com'
          />
          <InputField
            label='Company Industry *'
            name='industry'
            autoComplete='off'
            type='text'
            placeholder='IT, Retail etc.'
          />
          <InputField
            label='Monthly Media Budget (EUR) *'
            type='number'
            name='budget'
            placeholder='1000'
            min={0}
          />
          <InputField
            textarea
            label='Your Message'
            name='message'
            placeholder='Hello, ...'
            maxLength={2000}
          />
          <label className='flex items-center gap-2'>
            <input type='checkbox' name='newsletter' className='w-5 h-5' /> Sign up for our New Wave
            newsletter.
          </label>
          <button
            className={`btn btn-fill self-end ${formSubmitted ? 'disabled:bg-lagoon-500' : ''}`}
            type='submit'
            disabled={isSubmitting || formSubmitted}
          >
            {formSubmitted ? 'Message Sent!' : isSubmitting ? '...' : 'Send'}
          </button>
        </Form>
      )}
    </Formik>
  )
}

type InputFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string
  name: string
  textarea?: boolean
}
const InputField = ({ textarea = false, label, ...delegated }: InputFieldProps) => {
  const [_, meta] = useField(delegated.name)
  const error = meta.touched && meta.error ? meta.error : null

  return (
    <label className='w-full flex flex-col relative gap-0.5'>
      <span className='text-sm'>{label}</span>
      <Field
        as={textarea ? 'textarea' : undefined}
        style={textarea ? { resize: 'none' } : undefined}
        rows={textarea ? 4 : undefined}
        className={`w-full pt-[5px] pb-[7px] px-2 rounded-md focus-visible:ring-saffron-400 focus-visible:ring-4 focus-visible:outline-none border ${
          error ? 'ring-2 ring-red-400 border-red-600' : 'border-gray-400'
        }`}
        {...delegated}
      />
      {error && (
        <div className='text-sm absolute text-red-500 -bottom-0.5 translate-y-full leading-3'>
          {error}
        </div>
      )}
    </label>
  )
}
