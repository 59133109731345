export const WaveBackgroundTextureSVG = ({ strokeWidth = '6px', overlay = false, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 25 1384 1725'
    width='100%'
    shapeRendering='optimizeSpeed'
    {...props}
  >
    <g
      style={{
        fill: 'none',
        stroke: '#e9e9f0',
        opacity: 0.53,
        strokeWidth,
        mixBlendMode: overlay ? 'overlay' : 'normal',
      }}
    >
      <path d='M-15.09 1728.74c475.11.21 950.22 0 1425.33 0M-15.09 1685.57c129.1.36 258.29-2.47 387.31 2.39 109.06 4.11 217.49-6.89 326.34-4.26 108.38 2.62 216.9-5.98 325.25-.71 128.51 6.26 257.76 2.58 386.42 2.58M-15.09 1642.4c130.28.03 260.56-4.41 390.4 7.55 54.83 5.05 111 7.49 165.83 1.22 53.62-6.14 105.58-16.37 159.81-15.03 107.21 2.65 214.76-14.87 321.69-1.89 127.95 15.54 258.83 8.14 387.6 8.14M-15.09 1599.23c69.44-.42 138.66-1.25 208.14-.32 63.61.85 123.61 5.25 186.61 15.12 55.86 8.75 111.98 13.45 167.69 1.47 52.82-11.36 102.3-29.33 157.03-28.79 105.79 1.04 211.67-25.62 316.61-3.44 31.54 6.67 63.21 15.14 95.62 16.06 28.05.8 56.3-.11 84.36-.11h209.27' />
      <path d='M-15.09 1556.06c69.24-1.06 137.88-1.97 207.22-.57 29.17.59 58.69-.84 87.7 2.7 35.47 4.33 70.18 13.49 104.99 21.3 57.82 12.97 112.75 20.86 169.91 1.59 51.53-17.37 98.05-44.16 153.75-45.02 104.81-1.61 207.41-37.19 310.56-5.23 31.85 9.87 63.92 22.32 97.47 24.84 27.88 2.09 56.53.4 84.46.4h209.27' />
      <path d='M-15.09 1512.89c31.17-.83 61.69-4.67 92.98-4.14 37.75.64 75.48 2.26 113.23 3.28 29.89.81 60.02-.62 89.6 4.45 37.29 6.4 73.38 18.57 109.64 29.07 60.17 17.42 113.8 29.34 172.3 1.66 49.67-23.5 93.2-59.77 150.23-62.39 48.95-2.25 95.19-11.8 143.27-20.42 22.38-4.01 45.07-7.24 67.84-7.84 33.14-.87 62.54 8.68 92.93 21.13 32.12 13.16 64.59 29.8 99.44 34.2 27.57 3.48 56.88.99 84.59.99h209.27' />
      <path d='M-15.09 1469.71c29.96-1.15 58.69-5.95 88.88-5.29 38.8.85 77.56 2.86 116.34 4.15 30.67 1.02 61.31-.38 91.46 6.18 39.11 8.51 76.56 23.63 114.26 36.77 32.85 11.45 68.84 25.41 104.13 25.86 25.45.32 49.38-10.98 70.54-24.12 47.19-29.3 88.37-75.14 146.74-79.58 47.83-3.64 91.38-15.12 137.86-26.14 21.45-5.09 43.53-9.36 65.63-10.14 34.26-1.22 64.03 12.1 94.1 27.26 32.35 16.31 65.18 37.12 101.4 43.48 27.17 4.77 57.27 1.57 84.71 1.57h209.27' />
      <path d='M-15.09 1426.54c28.91-1.42 55.83-7.1 85.13-6.34 39.76 1.04 79.47 3.41 119.21 4.96 31.39 1.22 62.42-.2 93.07 7.65 40.81 10.45 79.5 28.31 118.51 43.88 33.11 13.21 70.93 30.62 107.28 31.12 25.83.36 49.7-14.14 69.53-29.23 44.74-34.05 83.73-89.28 143.57-95.29 47.03-4.72 87.86-18.02 132.93-31.34 20.58-6.08 42.1-11.26 63.64-12.22 35.3-1.58 65.52 15.36 95.17 32.79 32.5 19.12 65.67 43.88 103.2 51.98 26.77 5.78 57.63 2.03 84.81 2.03h209.27' />
      <path d='M-15.09 1383.37c28.09-1.59 53.39-8.03 82.07-7.19 40.57 1.19 81.08 3.87 121.62 5.63 31.88 1.39 63.24-.16 94.21 8.64 42.27 12.01 81.99 32.23 122.09 49.85 33.32 14.64 72.5 34.76 109.79 35.31 26.37.38 49.89-16.47 68.76-33.12 42.63-37.63 79.64-101.29 140.97-108.19 46.67-5.25 84.93-20.21 128.89-35.57 19.87-6.94 40.92-12.76 62.07-13.86 36.26-1.88 66.77 17.96 96.05 37.2 32.55 21.4 66.02 49.7 104.69 59.03 26.46 6.38 57.89 2.28 84.86 2.28h209.27' />
      <path d='M-15.09 1340.2c27.54-1.62 51.68-8.64 80-7.77 41.15 1.27 82.24 4.2 123.36 6.12 31.98 1.49 63.66-.36 94.64 8.91 43.38 12.98 83.82 35.05 124.67 54.16 33.57 15.7 73.26 37.32 111.34 37.89 27 .4 49.9-17.28 68.32-35.2 41.12-40.01 76.42-110.29 139.21-116.97 46.8-4.99 82.88-21.45 126.16-38.39 19.46-7.62 40.12-13.69 61.11-14.86 37.14-2.07 67.53 19.34 96.67 39.96 32.41 22.93 66.19 54.24 105.73 63.96 26.31 6.46 58.01 2.2 84.84 2.2h209.27' />
      <path d='M-15.09 1297.03c12.16-.58 21.05-3.65 32.56-6.37 15.21-3.59 31.35-2.24 46.83-1.78 41.37 1.22 82.65 4.55 123.99 6.58 31.68 1.56 63.7-.93 94.41 8.23 43.95 13.12 84.65 36.46 125.72 56.38 33.84 16.41 73.01 37.99 111.68 38.48 27.63.35 49.71-16.17 68.36-35.12 40.59-41.25 74.24-114.8 138.44-120.35 47.01-4.06 82.47-21.62 125.49-38.99 19.38-7.83 39.92-13.9 60.91-15.06 37.49-2.09 67.71 19.5 96.85 40.64 32.16 23.34 65.9 55.58 105.63 65.09 26.68 6.39 59.06 1.51 86.27 1.65 69.53.37 139.06.89 208.59.62' />
      <path d='M-15.09 1253.86c13.7-.66 21.46-5.14 33.97-9.06 14.94-4.69 31.12-3.25 46.58-2.67 40.51 1.5 80.79 6.59 121.19 9.58 36.86 2.73 72.57-.2 107.8 12.9 37.91 14.09 73.07 35.02 108.75 53.8 33.85 17.82 72.12 40.32 111.32 42.39 32.43 1.71 56.41-18.44 76.82-41.42 38.07-42.88 68.08-109.89 130.84-117.12 44.62-5.14 83.27-17.61 125.69-31.56 22.59-7.43 46.92-14.55 70.89-15.03 34.49-.69 64.67 18.83 92.65 37 27.03 17.55 53.92 37.95 84.92 48.05 33.74 10.99 74.38 2.24 109.72 4.2 73.05 4.05 145.83 8.94 219.08 8.57' />
      <path d='M-15.09 1210.69c15.56-1.5 22.67-7.85 36.27-13.82 15-6.59 29.43-5.76 45.59-4.81 38.71 2.28 76.96 9.51 115.4 14.26 34.58 4.27 70.68.9 103.68 13.21 42.14 15.72 80.07 42 118.66 64.61 31.81 18.64 68.63 42.53 106.6 44.69 31.5 1.8 54.59-19.04 73.53-41.8 35.26-42.4 65.37-117.86 127.74-123.04 46.53-3.86 90.59-12.36 136.34-21.5 24.09-4.81 48.97-9.49 73.61-9.88 29.33-.46 55.42 9.67 81.7 21.81 26.43 12.21 52.32 26.43 81.52 30.81 20.16 3.02 40.83-1.1 61.12-1.23 21.13-.13 42.22 1.45 63.18 4.08 35.86 4.49 71.47 13.4 107.67 14.75 45.76 1.71 91.53 6.02 137.31 6.76' />
      <path d='M-15.09 1167.52c23.03-3.74 36.52-24.1 58.93-27.89 27.16-4.59 60.28 5.23 86.77 10.53 26.73 5.36 53.37 11.51 80.45 14.92 31.33 3.95 57.9 4.39 86.87 18.83 60.83 30.31 111.16 79.79 174.05 106.18 53.04 22.26 85.85-5.27 115.53-48.37 25.12-36.48 49.17-94.93 93.96-111.36 18.69-6.86 42.33-3.58 62.08-4.48 20.03-.91 40.03-2.4 60.02-4.01 39.36-3.17 78.69-6.72 118.2-7.38 35.41-.59 68.99 4.9 104 8.57 29.11 3.05 56.99-1.01 86.12-2.09 42.34-1.58 83.83 4.09 125.05 13.39 83.86 18.92 168.86 27.6 254.97 31.15' />
      <path d='M-15.09 1124.35c25.2-5.56 38.19-30.52 62.45-36.19 27.08-6.33 63.27 7.2 89.2 14.2 25.17 6.79 50.27 14.39 76.01 18.77 30.39 5.17 55.64 5.48 83.39 20.94 60.95 33.96 109.41 88.57 172.97 118.05 54.04 25.06 86.09-9.54 113.32-53.97 22.53-36.77 44.38-95.76 87.9-112.57 18.79-7.25 42.45-3.21 62.29-2.71 21.24.53 42.49 1.08 63.73 1.63 43.32 1.12 86.65 2.26 129.97 3.31 32.68.8 57.52-8.16 86.55-21.31 68.37-30.97 152.23-17.83 222.1 1.6 34.47 9.59 68.99 23.87 104.87 27.18 62.91 5.81 125.94 14.39 189 18.15' />
      <path d='M-15.09 1081.18c28.35-7.2 42.92-39.08 71.39-43.26 25.76-3.78 56.16 8.48 80.25 16.36 26.03 8.52 51.87 18.26 78.84 23.47 30.5 5.9 53.82 6.46 81.49 23.54 59.2 36.54 105.19 93.16 167.4 124.95 19.37 9.9 39.93 16.46 60.45 6.21 22.95-11.46 37.54-36.38 49.87-57.82 21.86-38 42.05-101.64 86.57-119.46 18.75-7.51 42.69-2.9 62.42-1.49 24.03 1.72 48 4.07 71.97 6.48 45.16 4.56 90.5 10.27 135.9 11.75 39.64 1.29 48.63-16.42 69.51-44.91 18.58-25.35 46.42-27.07 76.06-28.52 55.96-2.74 110.47 6.82 163.59 24.14 35.19 11.48 70.53 28.7 107.7 32.56 69.52 7.22 139.2 17.58 208.94 22.37' />
      <path d='M-15.09 1038c29.54-7.28 43.25-41.57 72.7-46.35 25.49-4.14 55.46 8.49 78.95 17.03 25.43 9.24 50.6 19.95 77.14 25.61 29.56 6.31 53.95 5.85 80.67 22.4 59.51 36.86 103.51 95.16 164.77 129.42 54.94 30.72 87.6-3.71 113.7-51.82 20.8-38.33 39.67-102.82 84.12-120.57 18.4-7.35 42.75-2.92 62.04-1.25 24.67 2.13 49.21 5.46 73.72 8.93 46.12 6.52 92.3 14.23 138.91 16.12 17.28.7 36.03 1.75 47.61-13.29 9.78-12.69 10.15-30.36 16.56-44.61 14.39-31.98 49.85-30.85 81.13-32.57 55.17-3.02 108.79 6.1 160.88 24 37.23 12.79 75.05 35.02 114.79 38.94 72.38 7.14 144.71 19.29 217.3 24.08' />
      <path d='M-15.09 994.83c27.97-8.1 45.95-38.57 74.29-43.01 24.62-3.86 55.36 8.37 78.8 15.22 25.53 7.46 50.89 15.78 76.99 21.04 29.42 5.94 54.43 6.45 80.7 22.87 59.83 37.39 103.36 97.07 165.05 131.73 54.19 30.45 87.17-5.29 114.47-51.36 22.43-37.87 43.29-99.11 87.23-117.46 18.57-7.76 42.72-2.96 62.28-1.36 23.86 1.96 47.64 4.75 71.39 7.66 44.9 5.5 89.79 11.67 135 13.97 18.04.92 35.43 2.02 47.98-12.89 10.94-12.99 13.64-31.24 21.41-45.97 16.25-30.84 48.01-30.18 80.31-31.76 53.53-2.62 105.15 7.21 155.34 25.39 37.26 13.49 74.74 35.47 114.89 38.85 71.58 6.01 142.65 18.32 214.33 23.27' />
      <path d='M-15.09 951.66c27.71-7.12 51.05-30.59 79.24-33.86 25.02-2.9 54.49 5.64 79.28 9.54 51.22 8.05 114.83 6.3 158.83 35.57 59.65 39.68 103.28 102.12 166.65 136.34 50.91 27.49 85.18-7.58 113.84-48.38 25.7-36.58 50.5-93.67 94.92-111.31 18.88-7.5 42.04-3.18 61.91-2 22.23 1.32 44.43 3.01 66.63 4.75 42.2 3.32 84.42 7.08 126.73 8.74 17.83.7 33.36.86 47.13-12.01 13.96-13.05 20.89-31.9 31.57-47.38 19.56-28.35 45.1-30.81 78.32-32.35 53.87-2.5 104.97 9.59 154.35 30.6 32.89 13.99 65.95 33.97 102.25 37.09 68.28 5.87 136.3 16.59 204.7 21.18' />
      <path d='M-15.09 908.49c27.38-4.5 53.35-16.77 80.79-20.25 27.83-3.53 58.27.79 86.45.16 52.03-1.17 109.76-9.73 154.87 20.93 61.34 41.7 104.87 107.86 170.06 144.1 50.3 27.96 88.6-8.16 120.55-45.64 28.88-33.87 57.96-83.45 102.16-98.88 19.03-6.64 41.25-3.15 61.14-2.81 18.66.32 37.32.48 55.99.61 38.37.26 76.73.44 115.09 1.36 21.68.52 38-.87 55.35-15.42 16.09-13.5 27.58-31.72 41.74-47.06 53.92-58.42 153.75-29.78 215.74 1.23 30.21 15.11 60.67 36.87 95.11 40.49 63.14 6.63 127 14.42 190.39 18.23' />
      <path d='M-15.09 865.32c59.48-2.66 119.05-2.68 177.24-16.17 52.59-12.19 103.09-28.08 150.86 5.12 61.45 42.71 104.17 108.59 167.56 148.73 50.05 31.69 92.18 2.4 130.59-32.92 34.14-31.4 68.56-76.28 114.97-89.54 33.05-9.44 73.81-4.18 108.39-7.85 32.25-3.42 64.34-6.93 96.82-7.38 23.75-.33 41.89-2.88 61.79-16.63 20.72-14.32 37.61-33.4 56.69-49.7 57.86-49.43 137.72-32.87 198.13 4.57 29.12 18.05 55.9 41.4 91.09 44.98 55.08 5.61 110.85 11.76 166.15 14.44' />
      <path d='M-15.09 822.15c33.05 2.46 65.77 11.27 98.86 12.42 28.96 1 56.7-11.15 82.61-22.7 24.18-10.77 48.03-23.06 73.72-29.92 30.62-8.18 53.52-2.35 79.25 16.12 63.11 45.33 105.85 115.1 171.16 157.56 47.93 31.16 92.75 6.38 134.64-23.44 39.75-28.29 79.78-68.32 128.86-79.21 32.65-7.24 65.84-4.62 98.62-14.06 27.58-7.94 54.09-14.86 82.94-16.44 56.6-3.09 91.9-38.96 136.44-70.17 61.96-43.41 128.55-35.47 186.07 12.17 22.29 18.46 43.07 41.33 73.08 45.27 48.15 6.32 98.85 9.11 147.4 10.71' />
      <path d='M-15.09 778.98c42.66 7.48 91.57 39.08 134.77 24.21 37.66-12.96 68.4-41.12 102.34-61.03 35.37-20.75 63.76-27.25 99.07-2.76 43.75 30.36 78.14 74.03 114.94 111.98 26.56 27.38 62 69.91 102.99 73.95 27.45 2.71 55.98-9.82 79.85-21.66 60.26-29.89 113.82-75.64 183.06-80.61 19.07-1.37 37.15-3.32 54.67-11.51 12.12-5.66 22.71-14.03 34.69-19.93 14.42-7.1 29.39-10.36 45.29-11.99 37.85-3.89 65.92-15.79 98.33-36.21 64.35-40.53 142.46-104.11 213.11-37.56 22.02 20.75 38.92 54.64 69.55 63.95 18.54 5.63 42.63 2.75 61.8 4.08 24.57 1.7 49.06 3.66 73.69 4.03' />
      <path d='M-15.09 735.81c28.09 6.89 53.06 19.65 79.64 30.74 22.79 9.51 39.29 16.35 62.69 5.74 39.37-17.85 68.53-53.71 102.7-79.17 35.53-26.47 63.17-32.25 100.11-4.8 38.59 28.68 69.61 67.34 102.07 102.44 28.32 30.61 63.86 76.84 106.74 87.24 31.12 7.55 67.65-7.02 95.96-18.47 62.77-25.37 121.22-62.74 190.28-66.52 16.79-.92 33.46-3.09 47.27-13.53 9.63-7.28 15.59-17.99 24.43-26.02 13.33-12.1 28.87-15.21 46.28-17.37 39.19-4.86 70.61-20.03 104.88-39.56 57.87-32.99 151.45-107.71 207.8-34.22 16.86 21.99 27.98 57.63 56.3 67.76 16.06 5.75 38.27 2.37 55.05 3.13 21.44.97 42.82 2.1 64.29 2.08' />
      <path d='M-15.09 692.64c30.53 8.44 56.3 24.87 84.55 38.75 23.62 11.61 39.91 19.19 64.2 6.13 41.15-22.12 68.72-64.67 103.93-94.34 29.81-25.1 54.68-33.74 88.24-11.33 46.26 30.88 81.41 79.04 118.55 119.65 26.93 29.45 63.71 77.56 106.77 82.59 29.14 3.4 60.92-7.15 88.08-16.03 67.21-21.97 130.82-54.87 202.4-58.88 16.2-.91 32.93-2.82 44.11-15.93 7.77-9.11 9.54-21.59 16.39-31.22 8.69-12.23 20.93-16.3 35.24-18.56 17.36-2.75 32.84-3.29 49.78-9.36 23.31-8.35 45.4-20.12 67.19-31.76 51.88-27.72 160.04-112.02 205.04-34.86 13.52 23.17 19.95 60.37 47.55 71.64 14.77 6.03 35.69 2.41 51.27 2.72 19.36.38 38.69.83 58.05.66' />
      <path d='M-15.09 649.47c31.9 8.13 57.4 26.94 86.2 42.2 22.75 12.06 40.47 20.38 64.44 7.4 42.37-22.94 68.66-70.16 103.76-101.88 28.93-26.14 54.89-34.03 88.2-11.71 46.57 31.2 81.69 80.25 118.83 121.41 26.68 29.57 63.64 78.76 106.88 83.29 28.88 3.03 61.2-7.86 88.36-15.79 68.16-19.9 135.13-50.57 206.78-54.54 16.08-.89 33.6-1.64 43.43-16.36 6.8-10.18 5.72-23.44 11.31-34.09 7.16-13.65 19.32-17.34 33.77-19.39 17.45-2.48 32.51-2.97 49.54-9.07 24.17-8.65 47.2-20.61 69.97-32.36 45.23-23.33 92.88-55.74 143.51-65.73 29.37-5.8 46.5 6.43 59.22 32.42 11.74 23.99 16.05 61.84 45.16 71.98 15.39 5.36 35.22 2.21 51.22 2.21 18.26 0 36.51.02 54.77 0' />
      <path d='M-15.09 606.3c31.04 8.22 56.39 25.71 84.72 40.24 23.31 11.95 40.05 19.54 64.25 6.53 41.39-22.26 68.51-65.96 103.62-96.09 30.51-26.18 54.3-32.67 88.42-10.23 44.91 29.53 80.17 73.84 117.28 112.07 26.43 27.23 65.17 75.22 106.27 79.56 27.94 2.95 61.49-8.84 88.3-14.87 67.83-15.27 136.14-37.72 205.67-43.52 16.93-1.41 32.91-2.27 44.07-16.73 8.1-10.5 9.23-24.58 16.25-35.63 8.5-13.38 20.08-17.66 35.23-19.8 43.28-6.13 78.25-20.28 117.52-40.34 44.28-22.63 90.24-53.01 139.57-63.21 28.81-5.96 46.24 6.78 60.17 31.31 13.26 23.35 19.99 57.7 48.3 68.04 15.43 5.64 34.85 2.67 50.96 2.67h54.74' />
      <path d='M-15.09 563.12c45.61 11.2 96.68 64.06 144.23 41.09 40.1-19.37 68.36-57.92 103.37-84.45 30.95-23.46 54.17-28.67 88.06-9.26 42.6 24.4 77.73 61.48 114.24 93.74 27.44 24.25 65.35 63.58 104.26 67.48 27.85 2.79 59.91-5.45 87.64-7.94 67.73-6.08 135.48-13.08 203.26-18.46 18.54-1.47 34.79-3.66 48.09-17.91 10.54-11.29 15.78-26.3 25.38-38.27 10.68-13.34 23.6-18.86 40.15-21.23 41.31-5.9 74.66-18.17 112.34-37.09 54.6-27.42 144.11-96.1 193.84-30.1 16.17 21.47 26.76 50.83 54.68 59.88 30.78 9.97 73.66 2.53 105.81 2.53' />
      <path d='M-15.09 519.95c45.06 9.12 90.6 51.94 137.41 33.61 38.64-15.13 68.21-47.43 103.02-69.06 30.36-18.86 54.57-22.79 87.3-8.65 39.84 17.22 74.62 45.24 110.23 69.53 29.55 20.16 64.43 46.21 101.33 49.74 28.52 2.72 57.77.3 86.54 3.07 66.38 6.38 133.09 19.43 200.02 15.96 46.89-2.43 61.27-31.6 91.58-61.29 13.66-13.38 28.6-20.64 47.42-23.33 38.56-5.52 69.53-15.04 105.02-32.38 56.67-27.68 129.18-80.06 185.92-28.05 19.94 18.28 35.35 42.08 63.26 48.91 32.63 7.98 72.79 1.94 106.26 1.94' />
      <path d='M-15.09 476.78c43.86 6.24 84.3 37.9 129.55 25.2 37.1-10.42 68.06-35.68 102.64-51.76 66.87-31.1 130.42 7.07 192.05 34.11 31.3 13.73 63.42 27.02 97.91 29.11 29.46 1.79 56.93 7.28 85.23 16.15 62.79 19.68 129.19 55.86 196.36 55.17 50.56-.52 76.51-37.96 112.25-67.07 16.69-13.6 34.48-22.76 55.89-25.78 35.36-5 63.7-11.47 96.61-26.91 58.1-27.27 115.44-61.95 176.98-25.87 23.87 14 44.63 32.13 72.96 36.5 34.38 5.31 72.16 1.14 106.9 1.14' />
      <path d='M-15.09 433.61c41.56 3.1 79.21 23.87 121.64 16.75 35.67-5.98 67.94-23.85 102.25-34.38 60.8-18.65 125.71-3.18 186.55 7.2 31.84 5.43 62.47 6.53 94.47 8.38 30.14 1.74 57.7 15.14 83.9 29.32 35.56 19.24 69.16 41.85 104.47 61.52 26.54 14.79 57.09 32.02 88.22 33.05 54.28 1.79 92.57-44.04 133.02-72.87 46.69-33.27 101.3-26.92 152.57-49.66 29.64-13.15 59.72-30.07 92.46-33.77 25.84-2.92 51.14 1.78 75.54 10.09 27.44 9.35 53.31 21.88 82.7 24.04 35.5 2.61 71.95.32 107.54.32' />
      <path d='M-15.09 390.44c38.68.56 75.68 11.92 114.7 9.16 34.57-2.45 67.84-13.16 101.91-18.76 27.89-4.58 56.42-4.14 84.57-7.21 32.34-3.53 64.74-6.47 97.13-9.57 29.41-2.81 62.06-10.83 91.48-9.71 30.84 1.18 59.2 22.8 82.78 40.57 35.24 26.55 67.47 56.82 102.32 83.85 24.46 18.98 54.83 43.63 87.08 45.72 57.35 3.72 107.33-49.12 151.45-78.06 21.8-14.3 45.64-26.9 71.83-30.39 28.99-3.86 53-5.42 80.7-16.61 26.39-10.66 51.79-23.43 80.53-26.31 26.29-2.64 53.56.35 79.44 4.7 30.44 5.12 60.37 12.45 91.41 12.9 35.97.52 72.03-.29 108.01-.29' />
      <path d='M-15.09 347.27c36.59-.66 73.1 3.6 109.75 3.3 33.99-.28 67.74-4.83 101.64-6.73 28.86-1.62 55.79.25 84.17-6.27 31.94-7.34 62.86-18.42 93.99-28.5 26.93-8.72 60.63-23.15 89.38-22.37 31.93.86 60.1 27.66 82.08 47.92 35.04 32.3 66.11 68.61 100.63 101.44 22.98 21.85 52.7 51.7 86.27 54.14 60.01 4.36 117.83-52.81 165.1-82.01 23.53-14.54 49.18-28.14 76.99-31.89 26.6-3.59 49.6-3.72 75.3-13.21 23.7-8.75 46.04-18.51 71.54-20.69 27.1-2.32 55.26-.7 82.32.9 68.85 4.08 137.21 3.97 206.17 3.97' />
      <path d='M-15.09 304.1c69.76-.13 139.52 0 209.28-.06 28.15-.02 56.83 2.28 84.36-4.81 32.43-8.35 62.13-24.8 92.27-38.91 26.78-12.54 58.25-27.73 88.64-26.85 33.48.97 59.81 26.85 82.01 49.36 35.08 35.57 65.2 75.66 99.63 111.84 22.47 23.61 51.05 54.62 85.96 56.17 62.05 2.75 121.78-54.28 171.53-84.09 24.15-14.47 50.41-28.6 78.75-32.39 25.43-3.4 48.33-3.46 73-11.93 22.24-7.64 43.29-15.87 67-17.85 27.57-2.31 56.04-.53 83.67-.5 69.75.08 139.5.04 209.25.04' />
      <path d='M-15.09 260.93h209.27c28.3 0 56.69 1.85 84.38-5.05 32.16-8.01 62-23.18 92.24-36.33 26.5-11.53 58.94-26.92 88.64-25.62 32.47 1.42 59.72 27.05 81.89 48.21 34.46 32.9 64.56 70.02 98.45 103.48 22.52 22.23 52.01 52.54 85.74 54.33 60 3.19 121.8-51.4 171.1-78.88 24.44-13.63 50.61-26.73 78.58-31 25.23-3.85 48.95-4.32 73.68-11.87 22.73-6.94 44.69-14.63 68.57-16.65 27.54-2.34 55.9-.62 83.5-.62h209.27' />
      <path d='M-15.09 217.76h209.27c28.28 0 56.55 1.54 84.38-4.42 31.78-6.8 61.86-19.67 92.23-30.88 27.1-10 59.17-23.12 88.64-21.91 31 1.27 58.1 22.51 80.98 41.44 33.49 27.73 63.31 59.56 96.33 87.82 23.33 19.98 52.46 45.03 84.69 46.6 58.71 2.87 119.31-43.02 169.87-67.18 24.72-11.81 50.75-22.8 78.12-26.52 25.67-3.48 50.46-4.06 75.85-10.23 52.79-12.83 101.5-14.73 155.68-14.73h209.27' />
      <path d='M-15.09 174.59h209.27c28.16 0 56.47 1.32 84.38-3.18 31.41-5.07 61.72-15.1 92.23-23.82 28.17-8.05 59.01-17.45 88.64-16.66 29.33.78 55.56 15.39 79.57 31.12 52.36 34.3 110.1 100.92 176.78 103.16 57.95 1.95 115.5-31.89 168.21-51.58 24.98-9.33 50.78-17.57 77.45-20.14 26.54-2.56 52.52-3.02 78.91-7.62 121.03-21.11 247.34-11.27 369.87-11.27' />
      <path d='M-15.09 131.41h209.27c60.9 0 116.8-6.18 176.61-17.92 58.82-11.55 110.86-16.83 166.63 8.67 55.24 25.26 109.04 68.25 172.14 69.06 57.12.74 111.85-20.5 166.4-34.58 52.83-13.63 105.24-11.78 159.02-17.75 124.16-13.78 250.46-7.48 375.25-7.48' />
      <path d='M-15.09 88.24c128.78 0 257.77 4.86 385.88-9.46 55.06-6.16 111.04-10.65 165.19 3.44 56.33 14.66 108.68 37.53 167.81 37.19 55.89-.32 109.6-10.67 164.7-18.67 53.75-7.8 107.41-5.36 161.48-8.55 126.47-7.45 253.61-3.95 380.27-3.95M-15.09 45.07c128.6 0 257.39 2.52 385.88-3.03 54.31-2.35 110-5.06 164.2.07 55.3 5.24 108.78 13.34 164.47 12.58 54.67-.74 108.81-3.37 163.41-6.37 54.42-2.98 108.82-.82 163.29-1.99 127.98-2.75 256.07-1.27 384.08-1.27' />
    </g>
  </svg>
)
