import heroImage from 'assets/images/hero-image.webp'
import WaveBeginningSVG from 'assets/svg/illustration-wave-beginning.svg'
import WaveFinalSVG from 'assets/svg/illustration-wave-final.svg'
import WaveMediumSVG from 'assets/svg/illustration-wave-medium.svg'
import LocationIcon from 'assets/svg/location-pin-icon.svg'
import MailIcon from 'assets/svg/mail-icon.svg'
import PhoneIcon from 'assets/svg/smartphone-icon.svg'
import { ContactForm } from 'components/ContactForm'
import { CTALink, HeroButton, WavyDivider } from 'components/UI'
import { ideas, services } from 'components/content'
import { TexturedWaveDividerSVG } from 'components/svg/TexturedWaveDividerSVG'
import { WaveBackgroundTextureSVG } from 'components/svg/WaveBackgroundTextureSVG'
import { motion } from 'framer-motion'
import { useDevelopment } from 'lib/hooks'
import Image from 'next/image'
import { ReactNode } from 'react'

export default function Home() {
  return (
    <>
      <HomeHero />
      <WavyDivider />
      <IdeasSection />
      <WavyDivider flipped />
      <ServicesSection />
      <WavyDivider pullFront />
      <ProcessSection />
      <QuestionSection />
      <div className='relative flex flex-col items-center text-light pt-44 md:pt-80 lg:pt-[520px] xl:pt-[600px] 2xl:pt-[860px]'>
        <div className='absolute top-0 h-full w-[200%] max-w-screen-md md:max-w-screen-lg lg:max-w-screen-xl xl:w-full xl:max-w-none'>
          <TexturedWaveDividerSVG />
          <div className='bg-twilight-900 h-full'>
            <WaveBackgroundTextureSVG overlay />
          </div>
        </div>
        <ContactSection title="Let's talk." />
        <div className='from-twilight-900 absolute bottom-0 h-24 w-full bg-gradient-to-t' />
      </div>
    </>
  )
}

export function ContactSection({ title }: { title: string }) {
  return (
    <div className='relative flex flex-col items-center justify-center gap-24 pb-24 lg:flex-row lg:items-start lg:gap-32 xl:gap-48 '>
      <div className='flex flex-col items-center gap-16'>
        <h2 className='relative text-4xl font-semibold lg:text-5xl'>{title}</h2>
        <div className='relative flex flex-col items-center gap-16'>
          <div className='flex flex-col'>
            <PhoneIcon height='64px' strokeWidth='1px' />
            <a className='text-lg underline' href='tel:+40724055116'>
              +40724055116
            </a>
          </div>
          <div className='flex flex-col'>
            <MailIcon height='64px' strokeWidth='1px' />
            <a className='text-lg underline' href='mailto:hello@novaunda.com'>
              hello@novaunda.com
            </a>
          </div>
          <div className='flex flex-col'>
            <LocationIcon height='64px' strokeWidth='1px' />
            <span className='text-md text-center'>
              Nicolae Constantinescu 35
              <br />
              Bucharest, 011711, Romania
            </span>
          </div>
        </div>
      </div>
      <div className='relative flex flex-col items-center gap-10 px-3'>
        <h2 className='relative text-3xl font-semibold  lg:text-5xl'>Tell us a bit about you</h2>
        <ContactForm />
      </div>
    </div>
  )
}

function HomeHero() {
  return (
    <div className='relative isolate flex h-[80vh] min-h-[640px] w-full flex-col items-center justify-center overflow-y-hidden px-10 lg:h-[90vh]'>
      <div className='absolute inset-0 -bottom-8 brightness-[20%] saturate-150'>
        <Image
          src={heroImage}
          alt='hero image'
          priority={true}
          placeholder='blur'
          fill
          style={{
            objectFit: 'cover',
            objectPosition: '20% 20%',
          }}
        />
        <div className='bg-twilight-900 absolute inset-0 opacity-90 mix-blend-overlay' />
      </div>
      <div className='justify-between relative flex h-full max-w-[500px] flex-col items-center pb-24 pt-40 lg:pb-48 lg:pt-60 max-h-[800px]'>
        <h1 className='text-light -mx-8 text-center text-5xl font-bold lg:text-6xl'>
          Turn the market tide with us
        </h1>
        <p className='text-light text-center text-xl font-semibold'>
          We will take a deep dive into your brand to help it reach its full potential. No fluff -
          just evidence based consultancy.
        </p>
        <HeroButton targetId='growth-journey'>SURF IT</HeroButton>
      </div>
    </div>
  )
}

function Idea({
  anchor,
  illustration,
  title,
  description,
}: {
  anchor: string
  illustration: ReactNode
  title: string
  description: string
}) {
  const dev = useDevelopment()
  const item = {
    hidden: { opacity: 0, y: 24 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.3, ease: 'easeOut' },
    },
  }

  const glow = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.6, delay: 0.2, ease: 'easeOut' } },
  }

  return (
    <motion.div
      key={anchor}
      id={anchor}
      variants={item}
      className='text-light group relative flex flex-col items-center gap-8 lg:grid lg:grid-cols-2'
      initial='hidden'
      whileInView='visible'
      viewport={{ margin: '0px 0px -150px 0px', once: !dev }}
    >
      <motion.div
        className='bg-gradient-radial pointer-events-none absolute top-56 h-[1000px] w-[1000px] -translate-y-1/2 rounded-full from-sky-500/25 to-sky-500/0 to-60% blur-lg group-odd:left-0 group-odd:-translate-x-1/2 group-even:right-0 group-even:translate-x-1/2 lg:top-40 lg:group-odd:-left-10 lg:group-even:-right-10'
        variants={glow}
      />
      <div className='relative mb-8 w-4/5 max-w-xs place-self-center lg:row-span-2 lg:mb-0 lg:group-even:row-start-1'>
        {illustration}
      </div>
      <h2 className='relative w-full text-3xl font-bold lg:row-start-1 lg:place-self-end lg:text-4xl'>
        {title}
      </h2>
      <p className='text-lg opacity-90 lg:place-self-start lg:text-xl'>{description}</p>
    </motion.div>
  )
}

function IdeasSection() {
  return (
    <div className='bg-twilight-900 flex w-full items-center justify-center px-8 pb-32 pt-32 lg:pb-52 lg:pt-60'>
      <div className='flex w-full max-w-lg flex-col items-center gap-52 lg:max-w-4xl xl:max-w-5xl'>
        {ideas.map(idea => (
          <Idea key={idea.title} {...idea} />
        ))}
        <CTALink href='/contact'>LET'S TALK</CTALink>
      </div>
    </div>
  )
}

function Service({
  title,
  description,
  right,
}: {
  title: string
  description: string
  right: boolean
}) {
  return (
    <motion.div
      initial={{ opacity: 0, x: right ? 40 : -40 }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3, ease: 'easeOut' }}
      viewport={{
        margin: '1000px 0px -140px 0px',
        once: true,
      }}
      // viewport={{ once: true }}
      className='group w-4/5 max-w-sm even:place-self-end'
    >
      <h3 className='mb-2 text-2xl font-bold group-even:text-end lg:mb-4 lg:text-3xl'>{title}</h3>
      <p className='text-lg opacity-80 group-even:text-end lg:text-xl'>{description}</p>
    </motion.div>
  )
}

export function ServicesSection() {
  return (
    <div className='bg-light relative -z-10 flex flex-col items-center gap-28 px-4 pb-40 pt-28 lg:gap-40 lg:pt-44'>
      <div className='absolute left-0 top-0 w-[180%] lg:w-[100%]'>
        <WaveBackgroundTextureSVG strokeWidth='4px' />
        <WaveBackgroundTextureSVG strokeWidth='4px' />
      </div>
      <h2 className='relative max-w-xl text-center text-4xl font-bold lg:max-w-2xl lg:text-5xl'>
        Our integrated marketing services will help you reach your{' '}
        <span className='text-saffron-500'>growth</span> potential
      </h2>
      <div className='relative flex w-full max-w-xl flex-col gap-20 px-4 lg:max-w-2xl xl:max-w-3xl'>
        {services.map(({ title, description }, i) => (
          <Service key={title} title={title} right={i % 2 === 1} description={description} />
        ))}
      </div>
    </div>
  )
}

function ProcessSection() {
  return (
    <div className='relative flex flex-col items-center gap-24 overflow-hidden pb-64 pt-44 md:pb-[360px] lg:pb-[500px] lg:pt-80 xl:gap-52 xl:pb-[640px] 2xl:pb-[840px]'>
      <div className='absolute bottom-0 w-[200%] max-w-screen-md rotate-180 md:max-w-screen-lg lg:max-w-screen-xl xl:w-full xl:max-w-none'>
        <TexturedWaveDividerSVG />
      </div>
      <div className='from-twilight-900 absolute top-0 h-40 w-full bg-gradient-to-b lg:h-80' />
      <h2 className='text-light relative text-center text-5xl font-bold xl:text-7xl'>
        How we do it
      </h2>
      <div className='flex w-full flex-col items-center gap-16'>
        <div className='text-lagoon-300 max-w-8xl relative flex w-full flex-col items-center gap-20 text-4xl lg:max-w-5xl lg:flex-row lg:justify-around lg:gap-0 xl:max-w-7xl xl:text-5xl'>
          <h3 className='text-center font-bold text-inherit'>Research</h3>
          <h3 className='text-center font-bold text-inherit'>Marketing Strategy</h3>
          <h3 className='text-center font-bold text-inherit'>Launch</h3>
        </div>
        <div className='relative hidden w-full flex-row items-end justify-around text-white lg:flex lg:max-w-6xl xl:max-w-7xl'>
          <div className='w-72 xl:w-80'>
            <WaveBeginningSVG />
          </div>
          <div className='w-72 xl:w-80'>
            <WaveMediumSVG />
          </div>
          <div className='w-72 xl:w-80'>
            <WaveFinalSVG />
          </div>
        </div>
      </div>
    </div>
  )
}

export function QuestionSection() {
  return (
    <div className='bg-saffron-500 -z-10 -my-64 flex items-center justify-center px-3 py-[320px]  text-center lg:-my-80 lg:py-[420px] xl:-my-[600px] xl:py-[710px]'>
      <div className='relative'>
        <aside className='text-light drop-shadow-question relative text-5xl font-bold leading-snug lg:text-7xl lg:leading-normal'>
          Are&nbsp;you&nbsp;ready to&nbsp;turn&nbsp;the
          <br />
          market tide?
        </aside>
      </div>
    </div>
  )
}
