import BrandSuccess from 'assets/svg/brand-success.svg'
import CustomersAreKey from 'assets/svg/customers-are-key.svg'
import EvidenceBasedConsultancy from 'assets/svg/evidence-based-consultancy.svg'
import SurfTheMarket from 'assets/svg/surf-the-market.svg'

export const ideas = [
  {
    title: 'Your growth journey begins at the core of your business',
    illustration: <BrandSuccess />,
    anchor: 'growth-journey',
    description:
      'We drive your market growth by identifying your key issues that stumble market expansion. We analyze your past performance, current position and future targets to assure your success in scaling your business.',
  },
  {
    title: 'Surf the market with us',
    illustration: <SurfTheMarket />,
    anchor: 'surf-the-market',
    description:
      'We are your new wave partner. When you work with us, expect an honest, no-frills open discussion. Our belief is that sustainable growth can only be achieved when prepared to face the brunt of the market. Therefore, our team will bring you answers like no others in the market.',
  },
  {
    title: 'Evidence based consultancy',
    illustration: <EvidenceBasedConsultancy />,
    anchor: 'evidence-based-consultancy',
    description:
      "We don't beat around the bush. Our approach is based on extensive research on businesses and startups alike to provide palpable results. We know what works and what doesn't, so you won't waste a penny on our collaboration.",
  },
  {
    title: 'Customers are key',
    illustration: <CustomersAreKey />,
    anchor: 'customers-are-key',
    description:
      'Businesses are sinking because they are disconnected from their clients. We are here to build a bridge to your customers through extensive communication methods that reach their hearts and solve their needs.',
  },
]

export const services = [
  {
    title: 'Value Branding',
    description: 'Our team will show your clients the value you bring to the world.',
  },
  {
    title: 'CRM',
    description:
      'Maintain a steady revenue stream and grow through valuable relationships with your clients.',
  },
  {
    title: 'Social Media Management',
    description: 'Reach your clients where they are.',
  },
  {
    title: 'Content Generation',
    description: 'Add value to your products with unique, proprietary content.',
  },
  {
    title: 'PPC',
    description: 'We know how to transform that intent into a YES.',
  },
  {
    title: 'SEO',
    description: 'Grow your traffic organically.',
  },
]
